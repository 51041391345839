import React, { useEffect, useState } from 'react';
import { AppLink, List, ListItem, Title } from 'src/components/atoms';
import { useStatic } from 'src/services';

const FooterStaticPageLinks = () => {
  const { getStaticList } = useStatic();
  const [navs, setNavs] = useState([]);

  useEffect(() => {
    getStaticList().then((res) => setNavs(res.data));
  }, []);
  return (
    <>
      <Title tag="h4" className="text-[#0B3262] !text-xl">
        Bilgi
      </Title>
      <List className="mt-3 lg:mt-5 flex flex-col gap-1 lg:gap-2">
        {navs.map((i: any, index: number) => {
          return (
            <ListItem key={index}>
              <AppLink href={'/' + i.type} className="text-[#0B3262] lg:text-base text-sm hover:underline">
                {i.title}
              </AppLink>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default FooterStaticPageLinks;
