import classNames from 'classnames';
import { isObject } from 'lodash';
import React from 'react';
import Button from '../atoms/button';

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children?: React.ReactNode | undefined;
  variant?:
    | 'none'
    | 'primary'
    | 'secondary-70'
    | 'secondary'
    | 'danger'
    | 'success'
    | 'warning'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-danger'
    | 'outline-success'
    | 'transparent'
    | undefined;
  isSubmitDisabled?: boolean | undefined;
  isSubmitLoading?: boolean | undefined;
  className?: string | undefined;
};

const DrawerSubmitButton = ({ onClick, variant = 'primary', className, isSubmitLoading = false, isSubmitDisabled = false, children }: Props) => {
  const classProps = classNames('min-w-[16.25rem]', className ? className : '');

  return isObject(children) ? (
    <>{children}</>
  ) : (
    <Button loading={isSubmitLoading} disabled={isSubmitDisabled} variant={variant} className={classProps} onClick={onClick}>
      {children}
    </Button>
  );
};

export default DrawerSubmitButton;
