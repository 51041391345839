import React from 'react';
import classNames from 'classnames';
import { isObject } from 'lodash';
import { Title } from '../atoms';

type Props = {
  title?: React.ReactNode | undefined;
  align?: 'left' | 'center' | 'right' | undefined;
  className?: string | undefined;
};

const DrawerHeader = ({ title, align = 'left', className }: Props) => {
  const classProps = classNames(
    'text-md lg:text-[1.425rem] !font-medium mb-4 pr-4',
    align === 'left' ? 'text-start' : '',
    align === 'center' ? 'text-center' : '',
    align === 'right' ? 'text-end' : '',
    className ? className : '',
  );
  return isObject(title) ? (
    <>{title}</>
  ) : (
    <Title tag="h2" className={classProps}>
      {title}
    </Title>
  );
};

export default DrawerHeader;
