import { AppLink, Box, Button, Card, List, ListItem, Span, Title, useIcon } from '@components';
import { useDeviceDetect } from '@hooks';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

type Props = {
  data: any;
  slug: string;
};

const BlogNav = ({ data, slug }: Props) => {
  const [open, setOpen] = useState(false);
  const { ChevronDownIcon } = useIcon();
  const { mobile } = useDeviceDetect();
  const router = useRouter();
  useEffect(() => {
    !mobile() && setOpen(true);
  }, [mobile]);

  useEffect(() => {
    setOpen(false);
  }, [router.asPath]);

  return (
    <Box className="col-span-6 lg:col-span-2">
      <Card className="lg:sticky !shadow-md top-36 transition-all rounded-lg border border-gray-100">
        {mobile() ? (
          <Button onClick={() => setOpen(!open)} className="flex items-center justify-between w-full">
            <Title tag="h2" className="text-[#0B3262] lg:pb-5 lg:mb-5 lg:border-b">
              Blog
            </Title>
            <Span className={`${open ? '-rotate-180' : ''} transition-all`}>
              <ChevronDownIcon color="#0B3262" width={24} height={24} />
            </Span>
          </Button>
        ) : (
          <Title tag="h2" className="text-[#0B3262] pb-5 mb-5 border-b">
            Blog
          </Title>
        )}
        {open && (
          <List className="lg:mt-0 mt-5">
            {data?.map((i: any, index: number) => {
              return (
                <ListItem key={index} className="max-w-[90%]">
                  <AppLink
                    className={`${slug === i?._id ? 'text-[#0B3262] font-semibold rounded' : ''} capitalize !text-left py-1 lg:py-2 truncate rounded block`}
                    href={`/blog/${i?._id}`}
                  >
                    {i?.title}
                  </AppLink>
                </ListItem>
              );
            })}
          </List>
        )}
      </Card>
    </Box>
  );
};

export default BlogNav;
