import React from 'react';
import { AppLink, List, ListItem, Title } from 'src/components/atoms';
import { navs } from 'src/mocks';

const FooterCategories = () => {
  return (
    <>
      <Title tag="h4" className="text-[#0B3262] !text-xl">
        Kategoriler
      </Title>
      <List className="mt-3 lg:mt-5 flex flex-col gap-1 lg:gap-2">
        {navs.map((i, index: number) => {
          return (
            <ListItem key={index}>
              <AppLink className="text-[#0B3262] lg:text-base text-sm hover:underline" href={i.slug}>
                {i.label}
              </AppLink>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default FooterCategories;
