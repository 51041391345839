import React from 'react';
import { Box, Button, Paragraph, Title } from 'src/components/atoms';
import { useIcon } from 'src/components/icons';

const FooterAppLinks = () => {
  const { AppleIcon, GooglePlayIcon } = useIcon();
  return (
    <>
      <Title tag="h4" className="text-[#0B3262] !text-xl">
        Mobil Uygulama
      </Title>
      <Paragraph className="!text-[#0B3262] mt-3 lg:mt-5 lg:text-base text-sm max-w-xs">
        <strong>ehliyetsinavim</strong> mobil uygulaması ile her an hazır ol.
      </Paragraph>
      <Box className="inline-flex lg:flex-col gap-2 mt-7">
        <Button className="border border-gray-100 text-sm hover:scale-110 bg-white transition-all duration-100 flex items-center justify-center gap-2 text-gray-800 shadow-md font-medium rounded-full px-5 py-3">
          <AppleIcon width={24} height={24} />
          App Store
        </Button>
        <Button className="border border-gray-100 text-sm hover:scale-110 bg-white transition-all duration-100 flex items-center justify-center gap-2 text-gray-800 shadow-md font-medium rounded-full px-5 py-3">
          <GooglePlayIcon width={24} height={24} /> Google Play
        </Button>
      </Box>
    </>
  );
};

export default FooterAppLinks;
