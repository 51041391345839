import React from 'react';
import { AppLink, Box, Title } from '@components';
import BlogCard from './blog-card';

type Props = {
  blogData: any;
};

const BlogList = ({ blogData }: Props) => {
  return (
    <Box className="my-10 lg:mt-20 lg:mb-0">
      <Box className="flex items-center gap-5">
        <Title tag="h2" className="text-[#0B3262] !text-xl lg:!text-2xl">
          Blog
        </Title>
        <AppLink href="/bloglar" className="underline text-sm font-semibold text-[#0B3262]">
          Tümünü Gör
        </AppLink>
      </Box>
      <Box className="grid lg:grid-cols-3 gap-3 lg:gap-10 mt-5 lg:mt-10">
        {blogData?.slice(0, 3)?.map((i: any, index: number) => {
          return <BlogCard key={index} slug={`/blog/${i._id}`} title={i.title} description={i.description} />;
        })}
      </Box>
    </Box>
  );
};

export default BlogList;
