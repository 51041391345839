import { http } from './utils/api';
import { ENDPOINTS } from './utils/endpoints';

const useStatic = () => {
  const getStaticList = () => {
    try {
      return http.get(ENDPOINTS.STATIC.ALL);
    } catch (e: any) {
      throw new Error(e);
    }
  };
  const getStaticByType = (type: string) => {
    const URI = ENDPOINTS.STATIC.BY_TYPE.replace('{type}', type);
    try {
      return http.get(URI);
    } catch (e: any) {
      throw new Error(e);
    }
  };

  return {
    getStaticList,
    getStaticByType,
  };
};

export default useStatic;
