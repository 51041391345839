// Helpers
import { easeInOutQuad } from './easing';

// Sleep
export const sleep = (ms = 1000): Promise<Response> => new Promise((resolve) => setTimeout(resolve, ms));
// ScrollTo
export const scrollTo = (element: HTMLElement, to: number, duration: number): void => {
  const start = element.scrollTop;
  const change = to - start;
  let currentTime = 0;
  const increment = 20;
  // Animate Scroll
  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) setTimeout(animateScroll, increment);
  };
  // Run
  animateScroll();
};

export const scrollToElement = (name: string) => {
  document.getElementsByClassName(name)[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
};
