import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { navs } from 'src/mocks';
import { Nav } from '../../atoms';
import NavLink from './nav-link';

type Props = {
  className?: string | undefined;
};

const HeaderNav = ({ className }: Props) => {
  const classProps = classnames('flex items-center lg:gap-6 xl:gap-10', className ? className : '');
  const router = useRouter();

  return (
    <Nav className={classProps}>
      {navs.map((item, index: number) => {
        return <NavLink key={index} item={item} active={router?.pathname === item.slug} />;
      })}
    </Nav>
  );
};

export default HeaderNav;
