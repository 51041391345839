import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';

type Props = {
  className?: string | undefined;
  href: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  children?: React.ReactNode | undefined;
  variant?:
    | 'none'
    | 'primary'
    | 'secondary-70'
    | 'secondary'
    | 'danger'
    | 'success'
    | 'warning'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-danger'
    | 'outline-success'
    | 'transparent'
    | undefined;
};

const AppLink = ({ className, children, href, variant, onClick }: Props) => {
  const classProps = classNames(
    'whitespace-nowrap text-center',
    className ? className : '',
    variant === 'none' ? 'p-4' : '',
    variant === 'primary'
      ? `rounded p-4 bg-[#0B3262] hover:bg-[#0B3262] focus:bg-[#0B3262] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#0B3262] active:shadow-lg transition duration-150 ease-in-out text-white`
      : '',
    variant === 'secondary'
      ? `rounded p-4 bg-gray-600 hover:bg-gray-500 focus:bg-gray-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out text-white`
      : '',
    variant === 'success'
      ? `rounded p-4 bg-green-600 hover:bg-green-500 focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-400 active:shadow-lg transition duration-150 ease-in-out text-white`
      : '',
    variant === 'danger'
      ? `rounded p-4 bg-red-600 hover:bg-red-500 focus:bg-red-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-400 active:shadow-lg transition duration-150 ease-in-out text-white`
      : '',
    variant === 'warning'
      ? `rounded p-4 bg-orange-500 hover:bg-orange-500 focus:bg-orange-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-orange-400 active:shadow-lg transition duration-150 ease-in-out text-white`
      : '',
    variant === 'outline-secondary'
      ? `border border-gray-900 rounded p-4 bg-transparent focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out text-gray-800`
      : '',
  );
  return (
    <Link href={href}>
      <a onClick={onClick} className={classProps}>
        {children}
      </a>
    </Link>
  );
};

export default AppLink;
