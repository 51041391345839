import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { isUndefined } from 'lodash';
import { Box, Button, Container, CountDown, Paragraph, Span, Strong } from '@components';

type Props = {
  setScreen: (screen: 'exam-started') => void;
  answerSize: number;
};

const BeginningLayout = ({ setScreen, answerSize }: Props) => {
  const [open, setOpen] = useState<undefined | boolean>(undefined);
  const router = useRouter();
  const device = router.query?.device
  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
      setScreen('exam-started');
    }, 3000);
  };

  return (
    <>
      <Container>
        <Box className="dynamic-vh flex flex-col items-center justify-center max-w-[645px] mx-auto">
          <Box className="text-[#0C3262] text-center">
            <Span className="flex items-center justify-center gap-3 text-6xl lg:text-7xl font-bold">
              <Span className="text-7xl lg:text-8xl block">{answerSize}</Span> soru
            </Span>
            <Span className="text-4xl lg:text-5xl block mt-3 lg:mt-5 font-bold">{Math.round(answerSize * 0.9)} dakika</Span>
            <Paragraph className="text-lg lg:text-xl text-left !text-[#0C3262] mt-5 lg:mt-10">
              Sınavda karşınıza çıkabilecek pek çok soruyu sizler için hazırlıyoruz. <strong>ehliyetsinavim.net</strong> tarafından hazırlanmış sınav sorularını çözerek sınav
              öncesi pratik yapabilirsiniz.
            </Paragraph>
            <Span className="text-lg lg:text-xl text-left block font-medium mt-8 lg:mt-10">
              Hazır olduğunuzda <Strong className="!text-[#0C3262] !font-extrabold">&quot;Başlat&quot;</Strong> butonuna basarak sınavı başlatabilirsin.
            </Span>
          </Box>
          <Box className="grid grid-cols-2 gap-5 mt-4 lg:mt-6 w-full">
            {device!=='mobile' && <Button onClick={() => router.back()} variant="outline-secondary">
              Geri Dön
            </Button>}
            <Button className={device==='mobile'?'col-span-2':''} onClick={() => handleClick()} variant="primary">
              Başlat
            </Button>
          </Box>
        </Box>
      </Container>
      {
        <Box
          className={`grid place-items-center fixed top-0 left-0 bottom-0 right-0 transition-all ease-in-out duration-300 ${
            open ? 'bg-white z-20 visible' : 'bg-transparent invisible'
          }`}
        >
          {!isUndefined(open) && <CountDown count={3} className="text-9xl" />}
        </Box>
      }
    </>
  );
};

export default BeginningLayout;
