import React from 'react';
import Image from 'next/image';
import { useDeviceDetect } from '@hooks';
import { AppLink, Box, Title } from '../../components/atoms';
import SSQCard from './ssq-card';

type Props = {
  faqData: any;
};
const SSQList = ({ faqData }: Props) => {
  const { mobile } = useDeviceDetect();

  return (
    <Box className="my-10 lg:mt-20 lg:mb-0">
      <Box className="flex items-center gap-5">
        <Title tag="h2" className="text-[#0B3262] !text-xl lg:!text-2xl">
          Sık Sorulan Sorular
        </Title>
        <AppLink href="/sik-sorulan-sorular" className="underline text-sm font-semibold text-[#0B3262]">
          Tümünü Gör
        </AppLink>
      </Box>
      <Box className="grid lg:grid-cols-3 grid-cols-1 gap-10 mt-5 lg:mt-10">
        <Box className="col-span-1 lg:col-span-2">
          <Box className="grid grid-cols-1 lg:grid-cols-1 justify-center gap-3 lg:gap-6">
            {faqData?.slice(0, 3)?.map((i: any, index: number) => {
              return <SSQCard key={index} question={i.question} answer={i.answer} />;
            })}
          </Box>
        </Box>
        {!mobile() && (
          <Box className="p-8">
            <Box className="relative h-[220px]">
              <Image className="scale-150" layout="fill" src="/images/app/home/2.png" alt="sik-sorulan-sorular" objectFit="contain" />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SSQList;
