import React from 'react';
import classnames from 'classnames';

type Props = {
  children?: React.ReactNode;
  className?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined;
};

const Span = ({ children, className, onClick }: Props) => {
  const classProps = classnames(className ? className : '');

  return (
    <span onClick={onClick} className={classProps}>
      {children}
    </span>
  );
};

export default Span;
