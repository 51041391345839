export const navs = [
  {
    id: 1,
    label: 'Çok Çıkan Sorular',
    slug: '/cok-cikan-sorular',
  },
  {
    id: 2,
    label: 'İlk Yardım',
    slug: '/ilk-yardim',
  },
  {
    id: 3,
    label: 'Trafik ve Çevre',
    slug: '/trafik-cevre',
  },
  {
    id: 4,
    label: 'Araç Tekniği',
    slug: '/arac-teknigi',
  },
  {
    id: 5,
    label: 'Trafik Adabı',
    slug: '/trafik-adabi',
  },
];
