import { Box, Spinner } from '@components';
import React from 'react';

const ExamDebounce = () => {
  return (
    <Box className="h-[100dvh] lg:h-screen grid place-items-center">
      <Spinner size="lg:w-32 lg:h-32 w-20 h-20" />
    </Box>
  );
};

export default ExamDebounce;
