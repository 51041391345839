import React from 'react';
import classnames from 'classnames';
import Spinner from './spinner';

type Props = {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string | undefined;
  variant?:
    | 'none'
    | 'primary'
    | 'secondary-70'
    | 'secondary'
    | 'danger'
    | 'success'
    | 'warning'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-danger'
    | 'outline-success'
    | 'transparent'
    | undefined;
  disabled?: boolean | undefined;
  type?: 'submit' | 'button' | undefined;
  loading?: boolean | undefined;
};

const Button = ({ children, onClick, className, disabled, variant = 'none', loading }: Props) => {
  const classProps: string = classnames(
    className ? className : '',
    variant === 'primary'
      ? `rounded p-4 bg-[#0B3262] ${
          disabled ? '' : ' hover:bg-[#0B3262] focus:bg-[#0B3262] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#0B3262] active:shadow-lg'
        } transition duration-150 ease-in-out text-white`
      : '',
    variant === 'secondary'
      ? `rounded p-4 bg-gray-600 ${
          disabled ? '' : 'hover:bg-gray-500 focus:bg-gray-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg'
        } transition duration-150 ease-in-out text-white`
      : '',
    variant === 'success'
      ? `rounded p-4 bg-green-600 ${
          disabled ? '' : 'hover:bg-green-500 focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-400 active:shadow-lg'
        } transition duration-150 ease-in-out text-white`
      : '',
    variant === 'danger'
      ? `rounded p-4 bg-red-600 ${
          disabled ? '' : 'hover:bg-red-500 focus:bg-red-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-400 active:shadow-lg'
        } transition duration-150 ease-in-out text-white`
      : '',
    variant === 'warning'
      ? `rounded p-4 bg-orange-500 ${
          disabled ? '' : 'hover:bg-orange-500 focus:bg-orange-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-orange-400 active:shadow-lg'
        } transition duration-150 ease-in-out text-white`
      : '',
    variant === 'outline-secondary'
      ? `border border-gray-900 rounded p-4 bg-transparent focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out text-gray-800`
      : '',
    disabled ? 'cursor-not-allowed bg-opacity-50' : '',
    'flex flex-row items-center justify-center',
  );

  return (
    <button disabled={disabled} className={classProps} onClick={onClick}>
      {children} {loading && <Spinner className="ml-2" />}
    </button>
  );
};

export default Button;
