import React from 'react';

type Props = {
  children?: React.ReactNode;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string | undefined;
};

const Title = ({ children, tag = 'h1', className }: Props) => {
  const Component = tag;

  return (
    <Component
      className={`${className} font-semibold ${tag === 'h1' ? 'text-2xl' : ''}${tag === 'h2' ? 'text-xl' : ''}${tag === 'h3' ? 'text-lg' : ''}${tag === 'h4' ? 'text-base' : ''}${
        tag === 'h5' ? 'text-sm' : ''
      }${tag === 'h6' ? 'text-xs' : ''}`}
    >
      {children}
    </Component>
  );
};

export default Title;
