import React from 'react';
import ReactSelect, { ActionMeta, MultiValue, SingleValue } from 'react-select';

type Props = {
  isMulti?: boolean | undefined;
  options?: { value: string | number; label: string | number }[];
  selected?: any | undefined;
  defaultValue?: { value: string | number; label: string | number }[];
  closeMenuOnSelect?: boolean | undefined;
  onChange?:
    | ((
        newValue:
          | MultiValue<{
              value: string | number;
              label: string | number;
            }>
          | SingleValue<{
              value: string | number;
              label: string | number;
            }>,
        actionMeta: ActionMeta<{
          value: string | number;
          label: string | number;
        }>,
      ) => void)
    | undefined;
};

const Select = ({ isMulti, defaultValue, options, closeMenuOnSelect = true, selected, onChange }: Props) => {
  const style = {
    control: (base: any) => ({
      ...base,
      padding: '5px 0',
      boxShadow: 'none',
    }),
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isSelected ? '#A6CEFD' : base.backgroundColor,
      color: state.isSelected ? '#202020' : base.color,
    }),
    clearIndicator: (base: any) => ({
      ...base,
      padding: '8px 0',
    }),
    menu: (base: any) => ({
      ...base,
      border: '1px solid #ddd',
      boxShadow: 'none',
    }),
    menuList: (base: any) => ({
      ...base,
      fontSize: '15px',
      padding: '0',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      display: 'flex',
      alignItems: 'items-start',
      justifyContent: 'flex-start',
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: '15px',
    }),
  };

  const noOptionsMessage = () => {
    return <span className="text-sm">Seçenek yok</span>;
  };

  const customFilter = (option: any, query: any) => {
    const label = String(option.data.label).toLocaleLowerCase('tr');
    if (label.startsWith(query.toLocaleLowerCase('tr')) && label.includes(query.toLocaleLowerCase('tr'))) return true;
    else return false;
  };

  return (
    <ReactSelect
      value={selected ? selected : ''}
      styles={style}
      closeMenuOnSelect={closeMenuOnSelect}
      defaultValue={defaultValue}
      components={{ IndicatorSeparator: () => null }}
      noOptionsMessage={noOptionsMessage}
      placeholder="Seçiniz"
      isMulti={isMulti}
      options={options}
      filterOption={customFilter}
      onChange={onChange}
    />
  );
};

export default Select;
