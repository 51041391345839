import React from 'react';
import { Box } from '../../components/atoms';
import AuthHero from './auth-hero';

type Props = {
  children?: React.ReactNode;
};

const AuthLayout = ({ children }: Props) => {
  return (
    <>
      <Box className="flex lg:gap-0 gap-20 flex-col lg:flex-row">
        <AuthHero />
        <Box className="grid place-items-center lg:h-screen min-w-full lg:min-w-[500px] ">{children}</Box>
      </Box>
    </>
  );
};

export default AuthLayout;
