import React from 'react';
import { AppLink, Box, Card, Span } from '../../components/atoms';

type Props = {
  year: number;
  type: 'sınav' | 'trafik adabı' | 'araç tekniği' | 'ilk yardım' | 'trafik çevre';
  slug: any;
  month: string
};

const MonthCard = ({ type, year, slug, month }: Props) => {

  return (
    <AppLink href={slug}>
      <Card className="border-1 border-[#0B3262] hover:scale-110 transition-all ease-in-out cursor-pointer whitespace-pre-wrap">
        <Box className="grid place-items-center text-center">
          <Span className="text-lg lg:text-2xl text-[#0B3262] font-semibold">
            {year} {month}
          </Span>
          <Span className="text-sm lg:text-xl text-[#0B3262] font-semibold">{type} soruları</Span>
        </Box>
      </Card>
    </AppLink>
  );
};

export default MonthCard;
