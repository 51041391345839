import classNames from 'classnames'
import React from 'react'
import ModalCancelButton from './modal-cancel-button'
import ModalSubmitButton from './modal-submit-button'

type Props = {
  submitButton?: React.ReactNode | undefined
  cancelButton?: React.ReactNode | undefined
  submitButtonVariant?: 'none' | 'primary' | 'secondary-70' | 'secondary' | 'danger' | 'success' | 'outline-primary' | 'outline-secondary' | 'outline-danger' | 'outline-success' | 'transparent' | undefined
  cancelButtonVariant?: 'none' | 'primary' | 'secondary-70' | 'secondary' | 'danger' | 'success' | 'outline-primary' | 'outline-secondary' | 'outline-danger' | 'outline-success' | 'transparent' | undefined
  onSubmit?: React.MouseEventHandler<HTMLButtonElement> | undefined
  onClose?: () => void
  className?: string | undefined
  direction?: 'row' | 'col' | undefined
  isSubmitLoading?: boolean | undefined
}

const ModalFooter = ({ onSubmit, onClose, submitButtonVariant, isSubmitLoading, cancelButtonVariant, direction = 'col', className, submitButton, cancelButton }: Props) => {
  const classProps = classNames(
    "flex gap-2",
    direction === 'row' ? 'flex-row' : '',
    direction === 'col' ? 'flex-col' : '',
    className ? className : ''
  )
  return (
    <div className={classProps}>
      {
        direction === 'col' ?
          <>
            <ModalSubmitButton isSubmitLoading={isSubmitLoading} variant={submitButtonVariant} onClick={onSubmit}>{submitButton}</ModalSubmitButton>
            <ModalCancelButton variant={cancelButtonVariant} onClick={onClose}>{cancelButton}</ModalCancelButton>
          </>
          :
          <>
            <ModalCancelButton variant={cancelButtonVariant} onClick={onClose}>{cancelButton}</ModalCancelButton>
            <ModalSubmitButton isSubmitLoading={isSubmitLoading} variant={submitButtonVariant} onClick={onSubmit}>{submitButton}</ModalSubmitButton>
          </>
      }
    </div>
  )
}

export default ModalFooter