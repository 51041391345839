import React from 'react';
import classnames from 'classnames';

type Props = {
  children?: React.ReactNode;
  className?: string | undefined;
};

const Strong = ({ children, className }: Props) => {
  const classProps = classnames(className ? className : '', 'font-medium text-gray-600');

  return <strong className={classProps}>{children}</strong>;
};

export default Strong;
