import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Span } from '@components';

interface Props {
  answerSize: number;
  locked: boolean;
  setLocked: (locked: boolean) => void;
}

const ExamTimer = ({ answerSize, locked, setLocked }: Props) => {
  const [timeLeft, setTimeLeft] = useState(Math.round(answerSize * 0.9) * 60);

  useEffect(() => {
    if (timeLeft > 0 && !locked) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime: number) => {
          if (prevTime === 0) {
            clearInterval(intervalId);
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    } else if (timeLeft === 0) {
      setLocked(true);
    }
  }, [timeLeft, locked]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <Box className="border-4 border-indigo-100 rounded-xl shadow-md shadow-[#cce3fe] fixed lg:absolute left-1/2 bottom-12 z-20 lg:bottom-[unset] lg:translate-x-[unset] lg:left-[unset] translate-x-[-50%] lg:right-12 lg:top-4 lg:px-20 px-11">
      <Box className="relative py-5 lg:py-6">
        <Span className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] font-semibold text-base lg:text-2xl text-slate-500">
          {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </Span>
      </Box>
    </Box>
  );
};

export default ExamTimer;
