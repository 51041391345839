export const examUrlParser = (type: any, count: any) => {
  return `/sinavim?icerik=${type.map((i: any) => i.value).join(',')}&soru-sayisi=${count.value}`;
};

const months = [
  { name: 'Ocak', value: 0 },
  { name: 'Şubat', value: 1 },
  { name: 'Mart', value: 2 },
  { name: 'Nisan', value: 3 },
  { name: 'Mayıs', value: 4 },
  { name: 'Haziran', value: 5 },
  { name: 'Temmuz', value: 6 },
  { name: 'Ağustos', value: 7 },
  { name: 'Eylül', value: 8 },
  { name: 'Ekim', value: 9 },
  { name: 'Kasım', value: 10 },
  { name: 'Aralık', value: 11 },
  { name: 'Şubat', value: 12 },
]


export const queryParserSSR = (router: any) => {
  let queries = router.query;
  let str = '';

  queries = Object.entries(queries).map(([key, value]) => {
    if (key === 'icerik') {
      return `type=${value}`;
    } else if (key === 'soru-sayisi') {
      return `limit=${value}`;
    } else if (key === 'yil') {
      return `year=${value}`;
    } else if (key === 'ay') {
      const month = months.find((i) => { if (i.name.toLocaleLowerCase() === value) return i })
      if (month) return `month=${month.value}`;
    }
  });
  str = queries.join('&');
  return str;
};
