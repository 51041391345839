import React from 'react';
import { Box, Title } from '@components';

type Props = {
  title: string;
  children: React.ReactNode;
};

const MainLayout = ({ title, children }: Props) => {
  return (
    <Box className="my-8 min-h-[calc(100vh-220px)]">
      <Title className="text-[#0B3262] text-2xl lg:text-3xl">{title}</Title>
      {children}
    </Box>
  );
};

export default MainLayout;
