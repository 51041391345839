import React from 'react';
import { Paragraph, Span, Title } from 'src/components/atoms';
import { useIcon } from 'src/components/icons';

const FooterInfo = () => {
  const { MimpeIcon } = useIcon();
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Title tag="h4" className="text-[#0B3262] !text-xl">
        Hakkımızda
      </Title>
      <Paragraph className="!text-[#0B3262] lg:max-w-sm lg:text-base text-sm mt-3 lg:mt-5">
        Ehliyet sınavında karşınıza çıkabilecek pek çok soruyu sizler için hazırlıyoruz. <strong>ehliyetsinavim.net</strong> tarafından hazırlanmış sınav sorularını çözerek sınav
        öncesi pratik yapabilirsiniz.
      </Paragraph>
      <Paragraph className="mt-7">
        <Span>
          <MimpeIcon width={100} height={30} />
        </Span>
        <Span className="mt-2 inline-block">© {currentYear} &#9829; ile yapıldı.</Span>
      </Paragraph>
    </>
  );
};

export default FooterInfo;
