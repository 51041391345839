export const topics = [
  { value: 'ilk-yardim', label: 'İlk Yardım' },
  { value: 'trafik-cevre', label: 'Trafik ve Çevre' },
  { value: 'arac-teknigi', label: 'Araç Tekniği' },
  { value: 'trafik-adabi', label: 'Trafik Adabı' },
];

export const counts = [
  { value: 12, label: 12 },
  { value: 24, label: 24 },
  { value: 48, label: 48 },
];
