import React, { useEffect, useState } from 'react';
import { isUndefined, size } from 'lodash';
import { Modal } from '@components';
import { scrollToElement } from '@utils';
import { AppLink, Box, Button, Card, Container, InnerHtml, Paragraph, Progress, Span, Strong, Title } from '../../components/atoms';
import AnswerOption from './answer-option';
import { useRouter } from 'next/router';

type Props = {
  qIndex: number;
  setQIndex: (qIndex: number) => void;
  questions: any;
  setUserAnswer: (qIndex: number, answer: string) => void;
  answers: string[];
  locked: boolean;
  setLocked: (locked: boolean) => void;
  showResult: boolean;
  setShowResult: (show: boolean) => void;
};

const QuestionCard = ({ qIndex, setQIndex, questions, setUserAnswer, answers, locked, setLocked, showResult, setShowResult }: Props) => {
  const [open, setOpen] = useState(false);
  const [emptyAnswers, setEmptyAnswers] = useState<any>([]);
  const [wrongAnswersCount, setWrongAnswersCount] = useState<number | undefined>(undefined);
  const [correctAnswersCount, setCorrectAnswersCount] = useState<number | undefined>(undefined);
  const [emptyAnswersCount, setEmptyAnswersCount] = useState<number | undefined>(undefined);
  const [status, setStatus] = useState<undefined | boolean>(undefined);
  const [loading, setLoading] = useState(false);
  const point = 100 / size(questions);
  const router = useRouter();
  const device = router.query?.device;

  const handleActiveQuestion = (index: number) => {
    setQIndex(index - 1);
    setOpen(false);
    scrollToElement(`opticLetter__${index - 1}`);
  };

  const handleComplete = () => {
    let __emptyAnswers = answers?.map((i: undefined | string, index: number) => {
      return { qIndex: index + 1, value: i };
    });
    __emptyAnswers = __emptyAnswers?.filter((i: any) => i.value === undefined);
    setEmptyAnswers(__emptyAnswers);
    setOpen(true);
  };

  useEffect(() => {
    if (questions[qIndex].rightOption === answers[qIndex]) setStatus(true); //* correct
    else if (isUndefined(answers[qIndex])) setStatus(undefined); //* empty
    else if (questions[qIndex].rightOption !== answers[qIndex] && !isUndefined(answers[qIndex])) setStatus(false); //* wrong
  }, [qIndex]);

  useEffect(() => {
    if (locked) {
      const wrongAnswers = answers?.filter((i: string, index: number) => {
        if (questions[index].rightOption !== i && i !== undefined) return i;
      });
      const correctAnswers = answers?.filter((i: string, index: number) => {
        if (questions[index].rightOption === i) return i;
      });
      const _emptyAnswersCount = size(questions) - (Number(size(correctAnswers)) + Number(size(wrongAnswers)));
      setQIndex(0);
      setWrongAnswersCount(size(wrongAnswers));
      setCorrectAnswersCount(size(correctAnswers));
      setEmptyAnswersCount(_emptyAnswersCount);
    }
  }, [locked]);

  const handleSubmit = () => {
    setLoading(true);
    setLocked(true);
    setTimeout(() => {
      setOpen(false);
    }, 0);
  };

  return (
    <>
      <Box className="flex flex-col col-span-12 lg:col-span-9 items-center lg:mt-0 lg:pb-0 pb-5">
        <Box className="lg:pr-5 w-full lg:h-[calc(100vh-250px)]">
          <Box className="lg:max-h-[calc(100vh-250px)] h-full overflow-y-auto app-scrollbar app-scrollbar--lg pt-0 pb-5 lg:px-5 px-0">
            <Card className="!shadow-md border-2 border-gray-100">
              <Span className="block mb-5 lg:mb-10 pl-2 text-xl lg:text-2xl font-semibold flex items-center justify-between">
                Soru - {qIndex + 1}{' '}
                {locked &&
                  (status ? (
                    <Span className="bg-green-200 py-2 w-20 text-center rounded-xl text-sm font-bold">Doğru</Span>
                  ) : status === false ? (
                    <Box className="text-sm font-bold flex items-center gap-2">
                      <Span className="bg-red-200 py-2 w-20 text-center rounded-xl">Yanlış</Span>
                      <Span>Doğru Cevap: {questions[qIndex].rightOption}</Span>
                    </Box>
                  ) : (
                    <Box className="text-sm font-bold flex items-center gap-2">
                      <Span className="bg-gray-200 py-2 w-20 text-center rounded-xl">Boş</Span>
                      <Span>Doğru Cevap: {questions[qIndex].rightOption}</Span>
                    </Box>
                  ))}
              </Span>
              <Title className="font-medium text-gray-800 pl-2 text-base lg:text-xl">
                <InnerHtml className="inner-style--title" data={questions[qIndex]?.question} />
              </Title>
              {questions[qIndex]?.answerOptions.map((i: any, index: number) => {
                return (
                  <AnswerOption
                    key={index}
                    locked={locked}
                    answerDescription={i.text}
                    answerLetter={i.label}
                    qIndex={qIndex}
                    setQIndex={setQIndex}
                    answers={answers}
                    setUserAnswer={setUserAnswer}
                  />
                );
              })}
            </Card>
          </Box>
        </Box>
        {/* next, back, finish, quit buttons  */}
        <Box className={`w-full lg:static fixed bottom-0 z-20 lg:border-0 border-t border-gray-200 left-0 right-0 py-3 lg:pb-0 px-5 mt-5 bg-white`}>
          <Box className={`flex ${qIndex !== 0 ? 'justify-between' : 'justify-end'}`}>
            {qIndex !== 0 && (
              <Button onClick={() => setQIndex(qIndex - 1)} className="px-10 font-medium text-base lg:text-xl lg:hover:scale-125 transition-all ease-in-out" variant="warning">
                Geri
              </Button>
            )}
            {qIndex !== size(answers) - 1 ? (
              <Button variant="primary" className="px-10 font-medium text-base lg:text-xl lg:hover:scale-125 transition-all ease-in-out" onClick={() => setQIndex(qIndex + 1)}>
                Atla
              </Button>
            ) : !locked ? (
              <Button variant="success" className="px-10 font-medium text-base lg:text-xl lg:hover:scale-125 transition-all ease-in-out" onClick={() => handleComplete()}>
                Bitir
              </Button>
            ) : (
              device !== 'mobile' && (
                <AppLink href="/" variant="primary" className="px-10 font-medium text-base lg:text-xl lg:hover:scale-125 transition-all ease-in-out">
                  Çıkış
                </AppLink>
              )
            )}
          </Box>
          <Box className="flex flex-col items-center justify-center mt-1">
            <Span className="text-xs text-gray-700 font-semibold mb-1">
              {qIndex + 1}/{size(answers)}
            </Span>
            <Progress width={((qIndex + 1) / size(answers)) * 100} />
          </Box>
        </Box>
      </Box>
      <Modal
        bodyClassName="mt-0"
        closeIcon={false}
        open={open}
        onClose={() => setOpen(false)}
        footerClassName="flex flex-col gap-5"
        cancelButton="İptal"
        submitButton="Sınavı Bitir"
        isSubmitLoading={loading}
        onSubmit={() => handleSubmit()}
      >
        <Box className="max-w-md">
          {size(emptyAnswers) > 0 && (
            <Box className="grid lg:grid-cols-11  grid-cols-6 gap-3 mb-10">
              {emptyAnswers.map((i: any, index: number) => {
                return (
                  <Button className="lg:hover:-translate-1 lg:hover:scale-125 lg:hover:duration-300" onClick={() => handleActiveQuestion(index + 1)} key={index}>
                    <Strong className="w-full p-2 border border-gray-300 rounded shadow-lg text-xs text-center">{i.qIndex}</Strong>
                  </Button>
                );
              })}
            </Box>
          )}
          <Paragraph className="text-center text-lg">
            {size(emptyAnswers) > 0 && (
              <>
                <Strong className="font-semibold">Boş bıraktığınız sorular var.</Strong> <br />
              </>
            )}
            {size(emptyAnswers) === 0 ? 'Sınavı bitirmek istediğinize emin misiniz?' : 'Yine de sınavı bitirmek istediğinize emin misiniz?'}
          </Paragraph>
        </Box>
      </Modal>
      {/* exam info for user */}
      {locked && showResult && (
        <Box className="fixed top-0 left-0 bottom-0 right-0 bg-white z-[1000] grid place-items-center">
          <Container className="grid place-items-center">
            <Box className="flex flex-col items-center justify-center max-w-2xl w-full">
              <Title className="mb-5 !text-[#0C3262] text-center">
                Puanınız {(Number(correctAnswersCount) * point).toFixed(0)}
                <br />
                {Number(correctAnswersCount)} doğru, {Number(wrongAnswersCount)} yanlış, {emptyAnswersCount} boş
              </Title>
              <Paragraph className="text-lg lg:text-xl block font-medium mb-8 !text-[#0C3262] text-center leading-8 lg:leading-10">
                Tebrikler sınavı tamamladınız. Sınav sorularını tekrar incelemek için <Strong className="font-bold text-[#0B3262]">&quot;Sınavı İncele&apos;ye&quot;</Strong>{' '}
                tıklayabilir veya anasayfaya dönebilirsiniz.
              </Paragraph>
              <Box className="grid lg:grid-cols-2 w-full gap-5">
                <Button onClick={() => setShowResult(false)} variant="primary">
                  Sınavı İncele
                </Button>
                {device !== 'mobile' && (
                  <AppLink href="/" variant="outline-secondary">
                    Anasayfa&apos;ya Dön
                  </AppLink>
                )}
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default QuestionCard;
