import classNames from 'classnames'
import React from 'react'
import Button from '../atoms/button'

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  children?: React.ReactNode | undefined
  variant?: 'none' | 'primary' | 'secondary-70' | 'secondary' | 'danger' | 'success' | 'outline-primary' | 'outline-secondary' | 'outline-danger' | 'outline-success' | 'transparent' | undefined
  isSubmitLoading?: boolean | undefined
}

const ModalSubmitButton = ({ onClick, isSubmitLoading, variant = 'primary', children }: Props) => {
  const classProps = classNames(
    "w-full",
  )
  return (
    <Button loading={isSubmitLoading} variant={variant} className={classProps} onClick={onClick}>{children}</Button>
  )
}

export default ModalSubmitButton