import React, { useEffect, useState } from 'react';
import { size } from 'lodash';
import { Button, InnerHtml, Span } from '../../components/atoms';
import AnimateCircle from './animate-circle';

type Props = {
  setUserAnswer: (qIndex: number, answer: string) => void;
  answerLetter: string;
  answerDescription: string;
  qIndex: number;
  setQIndex: (qIndex: number) => void;
  answers?: string[];
  locked: boolean;
};

const AnswerOption = ({ setUserAnswer, answerLetter, locked, answerDescription, qIndex, setQIndex, answers }: Props) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (animate) {
      setTimeout(() => {
        setAnimate(false);
      }, 500);
      setTimeout(() => {
        if (size(answers) - 1 === qIndex) {
          return;
        }
        setQIndex(qIndex + 1);
      }, 500);
    }
  }, [animate]);

  const handleChange = (qIndex: number, answerLetter: string) => {
    if (!locked) {
      setUserAnswer(qIndex, answerLetter);
      setAnimate(true);
    }
  };

  return (
    <Button
      onClick={() => handleChange(qIndex, answerLetter)}
      variant="transparent"
      className={`block relative mt-1 w-full first:mt-2 rounded-xl pl-2 pr-10 py-3 ${
        answers?.find((j: string, index: number) => index === qIndex && answerLetter === j) && !animate ? '' : ''
      }`}
    >
      {answers?.find((j: string, index: number) => index === qIndex && answerLetter === j) && (
        <AnimateCircle noAnimate={!animate} className="absolute top-1/2 -left-[35px] lg:-left-[30px] translate-y-[-44.5%] lg:translate-y-[-43%] scale-50 lg:scale-[.83]" />
      )}
      <Span className="text-lg lg:text-3xl text-gray-700 font-medium z-20">
        {answerLetter}
        {')'}
      </Span>
      <Span className="text-lg lg:text-2xl text-gray-700 font-normal text-left w-full pl-4 z-20">
        {' '}
        <InnerHtml className="inner-style--option" data={answerDescription} />
      </Span>
    </Button>
  );
};
export default AnswerOption;
