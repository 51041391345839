// Third Party Modules
import { HYDRATE } from 'next-redux-wrapper';
import { createSlice } from '@reduxjs/toolkit';
import assign from 'lodash/assign';
import { UserLoginInterface } from '@models';

// AppSettingState

export interface AppSettingState {
  user: any;
  rememberMe: UserLoginInterface;
}
// InitialState
const initialState = { user: {}, rememberMe: {} } as AppSettingState;
// Slice
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUser: (state, action) => assign(state, { user: action.payload }),
    setRememberMe: (state, action) => assign(state, { rememberMe: action.payload }),
    removeUser: (state) => assign(state, { user: {} }),
  },
  extraReducers: { [HYDRATE]: (state, { payload }) => ({ ...state, ...payload.app }) },
});
export { appSlice };
