import classNames from 'classnames';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string | undefined;
  isFixedFooter?: boolean | undefined;
};

const DrawerBody = ({ children, isFixedFooter = false, className }: Props) => {
  const classProps = classNames('mb-4 lg:mb-6 overflow-auto', isFixedFooter ? 'h-[calc(100vh-220px)] lg:h-[calc(100vh-100px)]' : '', className ? className : '');
  return <div className={classProps}>{children}</div>;
};

export default DrawerBody;
