import React from 'react';
import { Box } from '../../components/atoms';
import MonthCard from './month-card';

type Props = {
  data: any;
  type: 'sınav' | 'trafik adabı' | 'araç tekniği' | 'ilk yardım' | 'trafik çevre';
};

interface IQuestionByMonth {
  year: number,
  groups: any
}

const QuestionListByMonth = ({ data, type }: Props) => {

  const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']

  return (
    <>
      {
        <Box className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-10 mt-6 lg:mt-8">
          {
            data.map((i: IQuestionByMonth) => {
              const { year } = i
              return Object.keys(i.groups).map((j: string, index) => {
                return (
                  <MonthCard key={index} year={year} slug={`sinavim?icerik=${i.groups[j][0].type}&ay=${months[Number(j)]?.toLocaleLowerCase()}&soru-sayisi=48`} month={months[Number(j)]} type={type} />
                )
              })
            })
          }
        </Box>
      }
    </>
  );
};

export default QuestionListByMonth;
