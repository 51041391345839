import React from 'react';
import classnames from 'classnames';
import AppLink from 'src/components/atoms/app-link';
import { Span } from '../../atoms';

type Props = {
  item: NavigationLinkInterface;
  active: boolean;
};

interface NavigationLinkInterface {
  id: number;
  label: string;
  slug: string;
}

const NavLink = ({ item, active }: Props) => {
  const classProps = classnames(
    'text-gray-800 text-base xl:text-lg font-semibold py-10 cursor-pointer relative group hover:text-green-700',
    active ? 'text-green-700 font-semibold' : 'lg:text-[#0B3262]',
  );

  return (
    <AppLink href={item.slug} className={classProps}>
      {item.label}
      <Span className={`absolute h-[2px] w-0 group-hover:w-full bg-[#2d8e82] -bottom-[1px] left-0 transiton-all duration-150`}></Span>
    </AppLink>
  );
};

export default NavLink;
