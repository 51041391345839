import classNames from 'classnames'
import React from 'react'

type Props = {
  title?: string | undefined;
  align?: 'left' | 'center' | 'right' | undefined;
  className?: string | undefined;
  children?: React.ReactNode;
}

const ModalHeader = ({ title, align = 'center', className, children }: Props) => {
  const classProps = classNames(
    'text-[1.125rem] font-medium mb-8',
    align === 'left' ? 'text-start' : '',
    align === 'center' ? 'text-center' : '',
    align === 'right' ? 'text-end' : '',
    className ? className : ''

  )
  return (title ? <h2 className={classProps}>{title}</h2> : <h2 className={classProps}>{children}</h2>)
}

export default ModalHeader