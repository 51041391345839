import React from 'react';

type Props = {
  width?: string | number | undefined;
  height?: string | number | undefined;
  color?: string | undefined;
};

const useIcon = () => {
  const CloseIcon = ({ width = 20, height = 20, color = 'currentColor' }: Props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-x"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    );
  };

  const AlertCircleIcon = ({ width = 20, height = 20, color = 'currentColor' }: Props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-alert-circle"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="8" x2="12" y2="12"></line>
        <line x1="12" y1="16" x2="12.01" y2="16"></line>
      </svg>
    );
  };

  const HamburgerIcon = ({ width = 20, height = 20, color = 'currentColor' }: Props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-menu"
      >
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
    );
  };

  const MimpeIcon = ({ width = 20, height = 20, color = 'currentColor' }: Props) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 180 50" fill={color}>
        <g clipPath="url(#clip0_2060_1101)">
          <path
            d="M44.5899 35.6201C42.4545 32.3621 39.3883 29.822 35.7899 28.3301C33.3765 27.5769 30.8996 27.0447 28.3899 26.7401C27.1299 26.5301 26.2799 26.4301 25.3099 26.3101C24.3399 26.1901 23.0099 26.0201 20.9299 25.6801C18.7228 25.4107 16.5374 24.9864 14.3899 24.4101C11.884 23.6875 9.51761 22.5482 7.38989 21.0401C5.65977 19.7915 4.12905 18.2877 2.84991 16.5801C2.68991 16.3601 2.53988 16.1301 2.37988 15.9001L5.11987 14.0801L5.5199 14.6401C6.59154 16.0768 7.87636 17.3414 9.3299 18.3901C11.1553 19.6803 13.1831 20.6571 15.3299 21.2801C17.3245 21.8044 19.3525 22.192 21.3999 22.4401C23.3999 22.7501 24.6299 22.9101 25.6799 23.0301C26.7299 23.1501 27.5599 23.2701 28.8799 23.4901C31.5968 23.814 34.2759 24.4 36.8799 25.2401C38.507 25.8525 40.0415 26.6871 41.4399 27.7201C43.6828 29.3925 45.6384 31.419 47.2299 33.7201L44.5899 35.6201Z"
            fill="#1A2A52"
          ></path>
          <path
            d="M25.02 50.0199C24.34 50.0199 23.64 50.0199 22.96 49.9399C16.4398 49.4168 10.3843 46.3591 6.09259 41.4227C1.80089 36.4863 -0.385143 30.0645 0.00341255 23.535C0.391968 17.0054 3.32418 10.8882 8.17101 6.4956C13.0178 2.10302 19.3932 -0.214964 25.9294 0.0388693C32.4656 0.292703 38.642 3.09816 43.1336 7.85338C47.6252 12.6086 50.0741 18.9348 49.9551 25.4748C49.8361 32.0149 47.1586 38.2478 42.497 42.8365C37.8354 47.4252 31.5611 50.0041 25.02 50.0199ZM25.02 3.25994C19.7009 3.24359 14.5601 5.17603 10.5689 8.69213C6.57772 12.2082 4.01264 17.0645 3.35833 22.3431C2.70402 27.6218 4.00584 32.9573 7.01776 37.3414C10.0297 41.7256 14.5431 44.8547 19.7051 46.1376C24.8672 47.4204 30.3203 46.768 35.0341 44.3038C39.7479 41.8395 43.3959 37.7341 45.2887 32.7632C47.1815 27.7923 47.188 22.3003 45.3071 17.3249C43.4261 12.3495 39.7879 8.23538 35.08 5.75994C31.9767 4.12913 28.5256 3.27151 25.02 3.25994Z"
            fill="#1A2A52"
          ></path>
          <path
            d="M39.3601 36.3801C38.5619 34.9298 37.4351 33.6866 36.0701 32.7501C34.0618 31.5482 31.8235 30.7817 29.5001 30.5001C29.0701 30.4101 28.7101 30.3601 28.5001 30.3201H28.4301C28.3301 30.5501 28.1801 30.8801 28.0201 31.3201C27.0004 33.426 26.4316 35.7217 26.3501 38.0601C26.3919 39.7127 26.82 41.3326 27.6001 42.7901L27.7501 43.0801H28.1901C33.1301 43.0801 36.8301 39.6101 37.8901 38.5301C38.4806 37.9459 39.0255 37.3174 39.5201 36.6501L39.3601 36.3801Z"
            fill="#008DDB"
          ></path>
          <path
            d="M11.2901 14.34C12.2509 15.6927 13.5145 16.8021 14.9801 17.58C17.1083 18.5467 19.4129 19.0641 21.7501 19.1L22.7501 19.16H22.8201C22.9001 18.91 23.0101 18.57 23.1301 18.16C23.9097 15.9551 24.2231 13.6123 24.0501 11.28C23.8204 9.6389 23.2109 8.07407 22.2701 6.70996L22.0901 6.43994L21.6401 6.48993C16.7401 7.05993 13.4401 10.87 12.5001 12.08C11.9781 12.7261 11.5068 13.4114 11.0901 14.1299L11.2901 14.34Z"
            fill="#1A2A52"
          ></path>
          <path
            d="M26.6801 26.3799L23.6101 25.17C23.8901 24.42 24.1801 23.68 24.4701 22.98C25.3581 20.8772 26.1097 18.7194 26.7201 16.52C26.7596 16.3797 26.8097 16.2426 26.8701 16.11C27.4454 14.0352 27.6118 11.8683 27.3601 9.72998C27.0695 7.34143 26.3263 5.03017 25.1701 2.91998L28.0201 1.21997C29.3711 3.70336 30.2493 6.41599 30.6101 9.21997C30.923 11.8336 30.7226 14.4831 30.0201 17.02C29.9865 17.1827 29.9328 17.3405 29.8601 17.4899C29.21 19.7756 28.4218 22.0197 27.5001 24.21C27.2201 24.91 26.9301 25.64 26.6601 26.36"
            fill="#1A2A52"
          ></path>
          <path
            d="M22.17 48.37C22.11 48.23 22.0199 48.06 21.9299 47.89C21.7674 47.6015 21.6205 47.3044 21.4899 47C21.4306 46.862 21.387 46.7178 21.36 46.57C20.12 43.4758 19.6698 40.1216 20.05 36.81V36.74L20.14 36.17C20.2305 35.3886 20.3708 34.6137 20.5599 33.85C20.952 32.3539 21.4294 30.8815 21.9899 29.44L22.14 29.02L25.46 29.55L25.08 30.55C24.5678 31.8798 24.1239 33.2349 23.7499 34.61C23.5982 35.2701 23.4814 35.9377 23.4 36.61C23.4 36.79 23.4 36.98 23.32 37.16C22.9985 39.9654 23.3906 42.8066 24.46 45.42C24.5216 45.5615 24.5685 45.709 24.6 45.86C24.66 46 24.74 46.15 24.82 46.3C24.9 46.45 25.0599 46.74 25.1799 47L22.17 48.37Z"
            fill="#1A2A52"
          ></path>
          <path
            d="M57.9599 36.8001V17.0201C57.8937 15.838 58.0877 14.6557 58.5284 13.5568C58.969 12.4579 59.6454 11.469 60.5099 10.6601C62.1702 9.08624 64.3826 8.22787 66.67 8.2701C67.8845 8.27003 69.0827 8.54959 70.1717 9.08718C71.2608 9.62477 72.2114 10.4059 72.9499 11.3701C73.6885 10.4059 74.6391 9.62477 75.7281 9.08718C76.8172 8.54959 78.0154 8.27003 79.23 8.2701C81.5294 8.227 83.7544 9.08475 85.4299 10.6601C86.3033 11.4641 86.9884 12.4511 87.4361 13.5505C87.8839 14.6499 88.0832 15.8347 88.0199 17.0201V36.8301H83.0199V17.0201C83.0199 13.8001 81.3499 12.3701 79.2099 12.3701C77.0699 12.3701 75.3999 13.8001 75.3999 17.0201V36.7901H70.4999V17.0201C70.4999 13.8001 68.7799 12.3701 66.6499 12.3701C64.5199 12.3701 62.8399 13.8001 62.8399 17.0201V36.7901L57.9599 36.8001Z"
            fill="#1A2A52"
          ></path>
          <path d="M93.47 16.1599H98.3699V36.7999H93.47V16.1599Z" fill="#1A2A52"></path>
          <path
            d="M103.9 36.8001V17.0201C103.831 15.8377 104.024 14.6546 104.464 13.5553C104.905 12.4559 105.583 11.4673 106.45 10.6601C108.11 9.08624 110.323 8.22787 112.61 8.2701C113.824 8.27003 115.023 8.54959 116.112 9.08718C117.201 9.62477 118.151 10.4059 118.89 11.3701C119.628 10.4059 120.579 9.62477 121.668 9.08718C122.757 8.54959 123.955 8.27003 125.17 8.2701C127.469 8.227 129.694 9.08475 131.37 10.6601C132.237 11.4673 132.915 12.4559 133.355 13.5553C133.796 14.6546 133.989 15.8377 133.92 17.0201V36.8301H129.02V17.0201C129.02 13.8001 127.35 12.3701 125.21 12.3701C123.07 12.3701 121.4 13.8001 121.4 17.0201V36.7901H116.5V17.0201C116.5 13.8001 114.78 12.3701 112.65 12.3701C110.52 12.3701 108.84 13.8001 108.84 17.0201V36.7901L103.9 36.8001Z"
            fill="#1A2A52"
          ></path>
          <path
            d="M156.33 28.8C156.374 29.9039 156.199 31.0057 155.816 32.0419C155.433 33.0781 154.849 34.0283 154.097 34.8378C153.345 35.6473 152.44 36.3001 151.435 36.7587C150.43 37.2173 149.344 37.4726 148.24 37.5099C146.661 37.5673 145.107 37.1111 143.81 36.21V43.21L138.91 46.6V17.02C138.841 15.8375 139.034 14.6545 139.474 13.5551C139.915 12.4558 140.593 11.4672 141.46 10.66C143.141 9.12245 145.337 8.26978 147.615 8.26978C149.893 8.26978 152.089 9.12245 153.77 10.66C154.638 11.4667 155.317 12.4549 155.76 13.5542C156.202 14.6535 156.397 15.8368 156.33 17.02V28.8ZM143.81 28.8C143.81 32.03 145.48 33.41 147.62 33.41C149.76 33.41 151.43 31.9399 151.43 28.7599V17.02C151.43 13.8 149.75 12.3699 147.62 12.3699C145.49 12.3699 143.81 13.8 143.81 17.02V28.8Z"
            fill="#008DDB"
          ></path>
          <path
            d="M166.5 24.5301V28.8001C166.5 32.1501 168.26 33.4501 170.23 33.4501C172.37 33.4501 174.04 32.0201 174.08 28.8001H179.02C179.086 29.9837 178.891 31.1672 178.451 32.2677C178.01 33.3681 177.334 34.3588 176.47 35.1701C174.776 36.7103 172.559 37.547 170.27 37.5101C168.05 37.5372 165.903 36.7143 164.27 35.2101C163.379 34.3979 162.68 33.3979 162.224 32.2823C161.767 31.1668 161.564 29.9637 161.63 28.7601V17.0201C161.564 15.838 161.758 14.6557 162.198 13.5568C162.639 12.4579 163.315 11.469 164.18 10.6601C165.84 9.08624 168.053 8.22787 170.34 8.2701C172.639 8.227 174.864 9.08475 176.54 10.6601C177.394 11.4738 178.059 12.4652 178.487 13.5642C178.916 14.6633 179.098 15.843 179.02 17.0201V24.5201L166.5 24.5301ZM166.5 20.4701H174.12V17.0201C174.12 13.8001 172.41 12.3701 170.27 12.3701C168.13 12.3701 166.5 13.8001 166.5 17.0201V20.4701Z"
            fill="#008DDB"
          ></path>
          <path
            d="M96.02 8.28003C96.5584 8.28003 97.0847 8.43981 97.5322 8.73914C97.9798 9.03846 98.3284 9.46383 98.534 9.96143C98.7395 10.459 98.7928 11.0065 98.687 11.5344C98.5813 12.0623 98.3212 12.5469 97.9398 12.9269C97.5584 13.3069 97.0728 13.5651 96.5445 13.6689C96.0162 13.7728 95.469 13.7175 94.9722 13.5101C94.4753 13.3027 94.0512 12.9525 93.7536 12.5039C93.4559 12.0553 93.2981 11.5284 93.3001 10.99C93.3001 10.6354 93.3699 10.2844 93.5056 9.95679C93.6413 9.62921 93.8401 9.33153 94.0908 9.08081C94.3416 8.83009 94.6392 8.63123 94.9668 8.49554C95.2944 8.35986 95.6454 8.29004 96 8.29004L96.02 8.28003Z"
            fill="#01A2F8"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_2060_1101">
            <rect width="179.03" height="50" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>
    );
  };

  const TimerIcon = ({ width = 32, height = 32, color = 'currentColor' }: Props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-clock"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 6 12 12 16 14"></polyline>
      </svg>
    );
  };

  const GooglePlayIcon = ({ width = 32, height = 32 }: Props) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
        <mask id="mask0_87_8320" maskUnits="userSpaceOnUse" x="7" y="3" width="24" height="26">
          <path
            d="M30.0484 14.4004C31.3172 15.0986 31.3172 16.9014 30.0484 17.5996L9.75627 28.7659C8.52052 29.4459 7 28.5634 7 27.1663L7 4.83374C7 3.43657 8.52052 2.55415 9.75627 3.23415L30.0484 14.4004Z"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#mask0_87_8320)">
          <path
            d="M7.63473 28.5466L20.2923 15.8179L7.84319 3.29883C7.34653 3.61721 7 4.1669 7 4.8339V27.1664C7 27.7355 7.25223 28.2191 7.63473 28.5466Z"
            fill="url(#paint0_linear_87_8320)"
          />
          <path
            d="M30.048 14.4003C31.3169 15.0985 31.3169 16.9012 30.048 17.5994L24.9287 20.4165L20.292 15.8175L24.6923 11.4531L30.048 14.4003Z"
            fill="url(#paint1_linear_87_8320)"
          />
          <path d="M24.9292 20.4168L20.2924 15.8179L7.63477 28.5466C8.19139 29.0232 9.02389 29.1691 9.75635 28.766L24.9292 20.4168Z" fill="url(#paint2_linear_87_8320)" />
          <path d="M7.84277 3.29865L20.2919 15.8177L24.6922 11.4533L9.75583 3.23415C9.11003 2.87878 8.38646 2.95013 7.84277 3.29865Z" fill="url(#paint3_linear_87_8320)" />
        </g>
        <defs>
          <linearGradient id="paint0_linear_87_8320" x1="15.6769" y1="10.874" x2="7.07106" y2="19.5506" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00C3FF" />
            <stop offset="1" stopColor="#1BE2FA" />
          </linearGradient>
          <linearGradient id="paint1_linear_87_8320" x1="20.292" y1="15.8176" x2="31.7381" y2="15.8176" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFCE00" />
            <stop offset="1" stopColor="#FFEA00" />
          </linearGradient>
          <linearGradient id="paint2_linear_87_8320" x1="7.36932" y1="30.1004" x2="22.595" y2="17.8937" gradientUnits="userSpaceOnUse">
            <stop stopColor="#DE2453" />
            <stop offset="1" stopColor="#FE3944" />
          </linearGradient>
          <linearGradient id="paint3_linear_87_8320" x1="8.10725" y1="1.90137" x2="22.5971" y2="13.7365" gradientUnits="userSpaceOnUse">
            <stop stopColor="#11D574" />
            <stop offset="1" stopColor="#01F176" />
          </linearGradient>
        </defs>
      </svg>
    );
  };

  const AppleIcon = ({ width = 32, height = 32 }: Props) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="#292020" width={width} height={height} viewBox="0 0 1920 1920">
        <path
          d="m1380.664 0 5.547 52.865c13.923 131.766-31.47 278.7-121.238 393.146-1.359 1.585-2.944 2.717-4.189 4.302 14.943-.566 29.885-1.698 45.394-.226 67.694 2.83 245.646 28.64 362.016 202.176l31.583 47.205-47.318 31.47c-40.752 25.81-165.839 118.748-164.141 293.077 1.924 220.742 179.876 301.68 200.14 310.17l48.562 20.377-16.3 50.26c-13.472 43.47-52.3 154.746-128.144 267.947-72.335 107.768-162.217 242.024-314.246 244.854-71.769 1.811-117.615-19.81-159.613-38.262-39.96-17.66-74.26-32.828-131.086-32.828-60.11 0-96.448 15.735-138.558 34.073-39.507 17.207-84.335 36.677-146.482 39.28-2.83.114-5.66.114-8.264.114-147.274 0-249.608-150.897-317.755-251.306l-5.187-7.735c-170.414-256.903-265.7-675.19-108.466-953.793 86.598-153.614 244.174-250.627 411.258-253.117 65.09-.906 124.974 19.47 176.594 39.846 8.83-119.88 65.883-231.722 125.087-303.944C1061.89 84.448 1206.448 7.245 1327.573 2.264L1380.664 0Zm-82.75 562.948c-73.694-7.81-144.331 21.282-207.157 46.639-47.432 19.13-88.297 35.545-126.22 35.545-32.375 0-66.335-10.98-101.654-24.791l-5.433-.453-.227-1.698c-7.471-3.056-14.942-6-22.64-9.056C785 588.984 730.55 562.608 676.102 567.25c-127.125 1.924-247.684 76.863-314.586 195.61-144.897 256.627-23.658 647.057 108.787 842.215 70.41 103.805 145.123 206.365 227.76 201.724 40.978-1.698 69.505-14.15 105.843-29.885 46.865-20.49 100.07-43.582 183.725-43.582 80.712 0 131.765 22.527 176.82 42.45 37.242 16.527 68.146 30.451 111.729 28.64 86.372-1.585 146.708-81.731 222.326-194.706 49.582-73.92 81.278-146.708 99.277-195.271-76.637-45.96-220.628-162.443-222.666-389.411-1.698-180.103 97.24-294.662 165.613-352.168-85.353-92.598-195.385-107.88-242.816-109.918Zm-23.093-438.314c-76.524 20.49-158.707 72.449-211.233 136.86l-.113.113c-46.525 56.827-95.994 150.784-101.088 247.457 84.674-12.678 165.273-71.769 213.61-133.01 58.411-74.374 93.39-164.821 98.824-251.42Z"
          fillRule="evenodd"
        />
      </svg>
    );
  };

  const ChevronDownIcon = ({ width = 32, height = 32, color = 'currentColor' }: Props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-chevron-down"
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    );
  };
  const ChevronUpIcon = ({ width = 32, height = 32, color = 'currentColor' }: Props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-chevron-up"
      >
        <polyline points="18 15 12 9 6 15"></polyline>
      </svg>
    );
  };

  return {
    CloseIcon,
    AlertCircleIcon,
    HamburgerIcon,
    MimpeIcon,
    TimerIcon,
    GooglePlayIcon,
    AppleIcon,
    ChevronDownIcon,
    ChevronUpIcon,
  };
};

export default useIcon;
