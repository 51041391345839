import React from 'react';
import classnames from 'classnames';
import { Box } from '../atoms';

type Props = {
  children: React.ReactNode;
  variant?: 'primary' | 'transparent' | 'success' | undefined;
  fill?: boolean | undefined;
  className?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  padding?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  isShadow?: boolean | undefined;
};

const Card = ({ variant = 'transparent', padding = 'md', isShadow = true, fill = false, className, onClick, children }: Props) => {
  const classProps = classnames(
    'rounded-2xl',
    variant === 'primary' ? 'bg-blue-50' : '',
    variant === 'success' ? 'bg-green-50' : '',
    fill ? 'min-h-[calc(100vh-200px)] min-lg:h-[calc(100vh-180px)]' : '',
    padding === 'xs' ? 'p-2 lg:p-3' : '',
    padding === 'sm' ? 'p-2 lg:p-5' : '',
    padding === 'md' ? 'p-3 lg:p-10' : '',
    padding === 'lg' ? 'p-5 lg:p-14' : '',
    padding === 'xl' ? 'p-7 lg:p-16' : '',
    isShadow ? 'shadow-xl' : '',
    className ? className : '',
  );

  return (
    <Box onClick={onClick} className={classProps}>
      {children}
    </Box>
  );
};

export default Card;
