import { Box, InnerHtml, Paragraph, Title } from '@components';
import Image from 'next/image';
import React from 'react';

type Props = {
  title: string;
  description: string;
  imgUrl: string;
};

const BlogDetail = ({ title, description, imgUrl }: Props) => {
  return (
    <Box className="col-span-6 lg:col-span-4">
      <Box className="mb-7 lg:mb-10 relative h-[200px] lg:h-[300px]">
        <Image priority className="rounded-lg" src={imgUrl} alt={title?.replaceAll(' ', '_')} layout="fill" objectFit="cover" />
      </Box>
      <Title className="text-[#0B3262] text-xl lg:text-3xl">{title}</Title>
      <InnerHtml className="mt-5 lg:mt-10 text-lg" data={description} />
    </Box>
  );
};

export default BlogDetail;
