import { http } from './utils/api';
import { ENDPOINTS } from './utils/endpoints';

const useQuestions = () => {
  const getQuestions = async (params?: string) => {
    const PARAMS = params ? `?${params}` : '';
    try {
      return http.get(`${ENDPOINTS.QUESTIONS.ALL}${PARAMS}`);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const getMonthsByType = async (type: string) => {
    const URI = ENDPOINTS.MONTHS.BY_TYPE.replace('{type}', type);
    try {
      return http.get(URI);
    } catch (error: any) {
      throw new Error(error);
    }
  };
  return {
    getQuestions,
    getMonthsByType,
  };
};

export default useQuestions;
