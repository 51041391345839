import React from 'react';

type Props = {
  width: number;
  label?: string | undefined;
};

const Progress = ({ width, label }: Props) => {
  return (
    <div className="w-full bg-gray-200 rounded-full">
      <div className="bg-[#0B3262] lg:text-sm text-xs font-medium text-blue-100 text-center p-1 leading-none rounded-full" style={{ width: `${width}%` }}>
        {label}
      </div>
    </div>
  );
};

export default Progress;
