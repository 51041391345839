import React from 'react';

type Props = {
  children?: React.ReactNode;
  className?: string | undefined;
  tag?: 'section' | 'div';
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
};

const Box = ({ children, onClick, className, tag = 'div' }: Props) => {
  const Component = tag;
  return (
    <Component onClick={onClick} className={className}>
      {children}
    </Component>
  );
};

export default Box;
