import React from 'react';
import classnames from 'classnames';

type Props = {
  children?: React.ReactNode;
  className?: string | undefined;
};

const Container = ({ className, children }: Props) => {
  const classProps = classnames('container container-max mx-auto', className ? className : '');
  return <div className={classProps}>{children}</div>;
};

export default Container;
