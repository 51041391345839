import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import Span from './span';

type Props = {
  count: number;
  className?: string | undefined;
};

const CountDown = ({ count, className }: Props) => {
  const [counter, setCounter] = useState(count);

  useEffect(() => {
    if (counter !== 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
  }, [counter]);

  const classProps = classnames(className ? className : '', 'text-[#0C3262] font-extrabold');

  return <Span className={classProps}>{counter}</Span>;
};

export default CountDown;
