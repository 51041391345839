// React
// Helpers
import React, { useEffect } from 'react';
// Next Modules
import { DefaultSeo } from 'next-seo';
import { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
// Third Party Modules
import NProgress from 'nprogress';
import { AuthLayout, CookieConsent } from 'src/features';
import { Footer, Header } from '@components';
import { scrollTo } from '@utils';
// Store
import { wrapper } from '@store';
// Styles
import 'tailwindcss/tailwind.css';
import '@styles/global.scss';
import Script from 'next/script';

// NProgress Config
NProgress.configure({ showSpinner: false });
// AppProps
type CustomAppProps = AppProps & { pathname?: string };

// App
const App = ({ Component, pageProps, pathname }: CustomAppProps): JSX.Element => {
  const router = useRouter();
  const device = router.query?.device;

  useEffect(() => {
    // Next Content
    const nextContent = document.getElementById('__next');
    // Router Events
    Router.events.on('routeChangeStart', () => NProgress.start());
    Router.events.on('routeChangeComplete', () => {
      // NProgress
      NProgress.done();
      // Scroll To Top
      if (nextContent instanceof HTMLElement) scrollTo(nextContent, 0, 0);
    });

    Router.events.on('routeChangeError', () => NProgress.done());
    // Load Transitions
    setTimeout(() => (nextContent instanceof HTMLElement ? nextContent.classList.add('loaded') : null), 500);
  }, []);
  const isHeader = ['/giris', '/kaydol', '/sinavim'].includes(router.pathname) ? false : true;
  const isFooter = ['/giris', '/kaydol', '/sinavim'].includes(router.pathname) ? false : true;
  const AuthPage = ['/giris', '/kaydol'].includes(router.pathname) ? true : false;
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        {/* Application Settings */}
        <meta name="application-name" content={process.env.NEXT_PUBLIC_SITENAME} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={process.env.NEXT_PUBLIC_SITENAME} />
        <meta name="description" content={process.env.NEXT_PUBLIC_DESCRIPTION} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#fffff" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#1d2338" />
        {/* Apple Icon */}
        <link rel="apple-touch-icon" sizes="32*32" href="/images/app-icons/32x32.png" />
        <link rel="apple-touch-icon" sizes="36*36" href="/images/app-icons/36x36.png" />
        <link rel="apple-touch-icon" sizes="57*57" href="/images/app-icons/57x57.png" />
        <link rel="apple-touch-icon" sizes="60*60" href="/images/app-icons/60x60.png" />
        <link rel="apple-touch-icon" sizes="72*72" href="/images/app-icons/72x72.png" />
        <link rel="apple-touch-icon" sizes="96*96" href="/images/app-icons/96x96.png" />
        <link rel="apple-touch-icon" sizes="114*114" href="/images/app-icons/114x114.png" />
        <link rel="apple-touch-icon" sizes="144*144" href="/images/app-icons/144x144.png" />
        <link rel="apple-touch-icon" sizes="152*152" href="/images/app-icons/152x152.png" />
        <link rel="apple-touch-icon" sizes="180*180" href="/images/app-icons/180x180.png" />
        {/* Favicon && Manifest */}
        <link rel="icon" sizes="32x32" href="/images/app-icons/32x32.png" />
        <link rel="icon" sizes="16x16" href="/images/app-icons/36x36" />
        <link rel="shortcut icon" href="/images/app-icons/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        {/* Preconnect */}
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://maps.googleapis.com" />
        {/* Global Css */}
        <style type="text/css">{`#__next:not(.loaded) *{transition:none!important}#__next:not(.loaded) ::before,#__next:not(.loaded) ::after{visibility:hidden!important}`}</style>
      </Head>
      <DefaultSeo
        title={`${process.env.NEXT_PUBLIC_TITLE} | ${process.env.NEXT_PUBLIC_DESCRIPTION}`}
        description={process.env.NEXT_PUBLIC_DESCRIPTION}
        canonical={`${process.env.NEXT_PUBLIC_SEO_HOST}${router.asPath}`}
        openGraph={{
          type: 'website',
          url: `${process.env.NEXT_PUBLIC_SEO_HOST}${router.asPath}`,
          site_name: process.env.NEXT_PUBLIC_SITENAME,
          title: process.env.NEXT_PUBLIC_TITLE,
          description: process.env.NEXT_PUBLIC_DESCRIPTION,
          images: [{ url: `${process.env.NEXT_PUBLIC_HOST}/images/common/og-default.png` }],
        }}
      />
      {!AuthPage && isHeader && <Header />}
      <main className="container container-max mx-auto">
        {AuthPage ? (
          <AuthLayout>
            <Component {...pageProps} />
          </AuthLayout>
        ) : (
          <Component {...pageProps} />
        )}
      </main>
      {isFooter && device !== 'mobile' && <Footer />}
      {device !== 'mobile' && <CookieConsent />}
      {/* Scripts */}
      <Script strategy='afterInteractive' src="https://www.googletagmanager.com/gtag/js?id=G-VR3X5XWF54" />
      <Script strategy='afterInteractive' id='google_analytics' >
        {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-VR3X5XWF54')`}
      </Script>
    </>
  );
};
// InitialProps
App.getInitialProps = (context: AppContext) => ({
  pathname: context.ctx.req?.url,
});
// Export
export default wrapper.withRedux(App);
