import classNames from 'classnames'
import React from 'react'
import Button from '../atoms/button'

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  children?: React.ReactNode
  variant?: 'none' | 'primary' | 'secondary-70' | 'secondary' | 'danger' | 'success' | 'outline-primary' | 'outline-secondary' | 'outline-danger' | 'outline-success' | 'transparent' | undefined

}

const ModalCancelButton = ({ onClick, variant = "transparent", children }: Props) => {
  const classProps = classNames(
    "w-full",
  )
  return (
    <Button variant={variant} className={classProps} onClick={onClick}>{children}</Button>

  )
}

export default ModalCancelButton