import { AppLink, Box, Button, Card, InnerHtml, Paragraph, Title } from '@components';
import Image from 'next/image';
import React from 'react';

type Props = {
  title: string;
  description: string;
  slug: string;
  imgUrl?: string | undefined;
};

const BlogCard = ({ title, description, slug, imgUrl }: Props) => {
  return (
    <Card className="relative !shadow-md !rounded-3xl border border-gray-100 !p-4 lg:!p-7 !pb-5 text-[#0B3262] lg:text-base text-sm lg:hover:scale-110 transition-all">
      {/* wrapper-link */}
      <AppLink href={slug} className="absolute top-0 left-0 bottom-0 right-0 z-10"></AppLink>
      {imgUrl && (
        <Box className="relative w-full h-36 mb-2">
          <Image layout="fill" src={`${imgUrl}`} alt={title?.replaceAll(' ', '_')} objectFit="cover" />
        </Box>
      )}
      <Title tag="h4" className="mb-2 line-clamp-1">
        {title}
      </Title>
      <Paragraph className="line-clamp-3 text-[15px]">
        <InnerHtml data={description} className="blog-seo" />
      </Paragraph>
      <Box className="flex justify-end mt-2">
        <Button className="underline text-xs font-medium">Devamını Oku</Button>
      </Box>
    </Card>
  );
};

export default BlogCard;
