import React from 'react';

type Props = {
  data: string;
  className?: string | undefined;
};

const InnerHtml = ({ data, className = '' }: Props) => {
  return <div className={className + ' inner-style'} dangerouslySetInnerHTML={{ __html: data }}></div>;
};

export default InnerHtml;
