import classNames from 'classnames';
import React from 'react';
import { Modal as ResponsiveModal } from 'react-responsive-modal';
import ModalBody from './modal-body';
import ModalCloseIcon from './modal-close-icon';
import ModalFooter from './modal-footer';
import ModalHeader from './modal-header';

type Props = {
  open: boolean;
  onClose: () => void;
  position?: 'center' | 'top-left' | 'top-right' | 'top-middle' | 'bottom-left' | 'bottom-middle' | 'bottom-right';
  closeIcon?: boolean | undefined;
  title?: string | undefined;
  titleAlign?: 'left' | 'center' | 'right' | undefined;
  submitButton?: React.ReactNode | undefined;
  cancelButton?: React.ReactNode | undefined;
  submitButtonVariant?:
    | 'none'
    | 'primary'
    | 'secondary-70'
    | 'secondary'
    | 'danger'
    | 'success'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-danger'
    | 'outline-success'
    | 'transparent'
    | undefined;
  cancelButtonVariant?:
    | 'none'
    | 'primary'
    | 'secondary-70'
    | 'secondary'
    | 'danger'
    | 'success'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-danger'
    | 'outline-success'
    | 'transparent'
    | undefined;
  animationDuration?: number | undefined;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  isSubmitLoading?: boolean | undefined;
  className?: string | undefined;
  headerClassName?: string | undefined;
  footerClassName?: string | undefined;
  bodyClassName?: string | undefined;
  footerDirection?: 'row' | 'col' | undefined;
  children?: React.ReactNode;
  isMobileFull?: boolean | undefined;
  isContentFull?: boolean | undefined;
};

const Modal = ({
  closeIcon = true,
  footerDirection = 'col',
  animationDuration = 500,
  title,
  isSubmitLoading,
  submitButton,
  cancelButton,
  position = 'center',
  onSubmit,
  open,
  onClose,
  className,
  headerClassName,
  footerClassName,
  bodyClassName,
  titleAlign,
  submitButtonVariant,
  cancelButtonVariant,
  children,
  isMobileFull,
  isContentFull,
}: Props) => {
  const classProps = classNames(
    isContentFull ? 'p-0 rounded' : 'p-8 rounded ',
    position === 'center' ? '' : 'absolute',
    position === 'top-left' ? 'top-0 left-0' : '',
    position === 'top-middle' ? 'top-50% left-1/2 translate-x-[-50%]' : '',
    position === 'top-right' ? 'top-0 right-0' : '',
    position === 'bottom-left' ? 'bottom-0 left-0' : '',
    position === 'bottom-middle' ? 'bottom-0 left-1/2 translate-x-[-50%]' : '',
    position === 'bottom-right' ? 'bottom-0 right-0' : '',
    isMobileFull ? 'm-0 lg:max-w-[800px] lg:static lg:h-[unset] lg:w-[unset] max-w-[100vw] absolute left-0 top-0 bottom-0 right-0 h-screen w-screen rounded-none' : '',
    className ? className : '',
  );

  return (
    <ResponsiveModal
      classNames={{ modal: classProps }}
      animationDuration={animationDuration}
      closeIcon={<></>}
      open={open}
      onClose={() => onClose()}
      center={position === 'center'}
    >
      {
        <>
          <div className="relative">
            <ModalHeader className={headerClassName} align={titleAlign} title={title} />
            {closeIcon && <ModalCloseIcon onClick={() => onClose()} />}
          </div>
          <ModalBody className={bodyClassName}>{children}</ModalBody>
          {(submitButton || cancelButton) && (
            <ModalFooter
              className={footerClassName}
              isSubmitLoading={isSubmitLoading}
              direction={footerDirection}
              onClose={() => onClose()}
              onSubmit={onSubmit}
              submitButtonVariant={submitButtonVariant}
              cancelButtonVariant={cancelButtonVariant}
              submitButton={submitButton}
              cancelButton={cancelButton}
            />
          )}
        </>
      }
    </ResponsiveModal>
  );
};

export default Modal;
