import { Box, Button, Paragraph, Title } from '@components';
import { getCookie, setCookie } from '@utils';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

const CoookieConsent = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const isCookieConcent = getCookie('concentCookie');
    if (!isCookieConcent) {
      setTimeout(() => {
        setOpen(true);
      }, 2000);
    }
  }, []);

  const handleSubmit = () => {
    setCookie('concentCookie', 'true');
    setOpen(false);
  };

  const classProps = classnames(
    'fixed shadow-2xl border border-gray-200 z-10 bg-white max-w-[476px] p-5 lg:p-8 rounded-2xl transition-all ease-in-out duration-1000',
    open ? 'right-4 left-4 lg:left-[unset] bottom-10 lg:right-10' : 'right-4 left-4 lg:left-[unset] -bottom-[1000px] lg:bottom-10 lg:-right-[1000px]',
  );

  return (
    <Box className={classProps}>
      <Title tag="h4">Çerez Onayı & Gizlilik</Title>
      <Paragraph className="!text-[#0B3262] leading-7 my-3 text-sm">Web sitemizi ve hizmetimizi optimize etmek için çerezleri kullanıyoruz.</Paragraph>
      <Box className="flex justify-end items-center gap-5">
        <Button className="text-xs font-medium py-3" variant="primary" onClick={() => handleSubmit()}>
          Tamam
        </Button>
      </Box>
    </Box>
  );
};

export default CoookieConsent;
