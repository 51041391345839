import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import AppLink from 'src/components/atoms/app-link';
import { navs } from 'src/mocks';
import Navigations from './header-nav';
import DrawerCloseIcon from 'src/components/drawer/drawer-close-icon';
import { useDeviceDetect } from '@hooks';
import { useIcon } from 'src/components/icons';
import { Box, Button, Container, Logo, Nav, Paragraph, Span } from 'src/components/atoms';
import { Drawer } from 'src/components/drawer';

const HeaderOrganism = () => {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const { HamburgerIcon, MimpeIcon } = useIcon();
  const currentYear = new Date().getFullYear();
  const { mobile } = useDeviceDetect();

  useEffect(() => {
    setOpen(false);
  }, [router.asPath]);

  return (
    <header className={`sticky scroll-shadow top-0 bg-white z-50`}>
      <Container>
        <Box className="flex justify-between items-center">
          {mobile() ? (
            <>
              <Logo width={130} height={33} />
              <Button onClick={() => setOpen(true)} variant="transparent" className="p-5 pr-0">
                <HamburgerIcon />
              </Button>
              <Drawer
                open={open}
                onClose={() => setOpen(false)}
                className="px-0 pt-0"
                closeIcon={false}
                title={
                  <>
                    <Box className="pl-4 pt-1 pr-20">
                      <Logo onClick={() => setOpen(false)} width={180} height={40} />
                    </Box>
                    <Box className="absolute top-1/2 right-4 translate-y-[-50%]">
                      <Span className="relative">
                        <DrawerCloseIcon onClick={() => setOpen(false)} />
                      </Span>
                    </Box>
                  </>
                }
                titleAlign="center"
                onSubmit={() => setOpen(false)}
                footerClassName="px-4"
                bodyClassName="flex flex-col justify-between"
                fixedFooter
              >
                <Nav className="flex flex-col text-center gap-1 mt-20">
                  {navs.map((item, index: number) => {
                    return (
                      <AppLink
                        onClick={() => setOpen(false)}
                        className={` bg-gray-100 py-5 font-medium ${item.slug === router.pathname ? 'text-white bg-[#2d8e82]' : 'text-gray-600'}`}
                        href={item.slug}
                        key={index}
                      >
                        {item.label}
                      </AppLink>
                    );
                  })}
                </Nav>
                <Paragraph className="grid place-items-center gap-3 mt-20">
                  <MimpeIcon width={100} height={30} />© {currentYear} &#9829; ile yapıldı.
                </Paragraph>
              </Drawer>
            </>
          ) : (
            <>
              <Logo />
              <Navigations />
            </>
          )}
        </Box>
      </Container>
    </header>
  );
};

export default HeaderOrganism;
