import React, { useEffect, useState } from 'react';
import { size } from 'lodash';
import AnswerList from './answer-list';
import BeginningLayout from './beginning-layout';
import ExamDebounce from './exam-debounce';
import ExamLayout from './exam-layout';
import QuestionCard from './question-card';

type Props = {
  answers: any;
  setAnswers: any;
  questions: any;
  loading: any;
};

const ExamSwitchScreen = ({ answers, setAnswers, questions, loading }: Props) => {
  const [screen, setScreen] = useState<'' | 'beginning' | 'exam-started'>('');
  const [qIndex, setQIndex] = useState(0);
  const [locked, setLocked] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    !loading && setScreen('beginning');
  }, [loading]);

  useEffect(() => {
    locked &&
      setTimeout(() => {
        setOpen(true);
      }, 0);
  }, [locked]);

  const setUserAnswer = (questionIndex: number, option: string) => {
    const tempAnswers = answers.map((i: string | undefined, index: number) => {
      return index === questionIndex ? option : i;
    });
    setAnswers(tempAnswers);
  };

  switch (screen) {
    case 'beginning':
      return <BeginningLayout answerSize={size(answers)} setScreen={setScreen} />;

    case 'exam-started':
      return (
        <>
          <ExamLayout title="Ehliyet Sınav Soruları" answerSize={size(answers)} locked={locked} setLocked={setLocked}>
            <AnswerList locked={locked} qIndex={qIndex} setQIndex={setQIndex} answers={answers} questions={questions} />
            <QuestionCard
              showResult={open}
              setShowResult={setOpen}
              locked={locked}
              setLocked={setLocked}
              qIndex={qIndex}
              setQIndex={setQIndex}
              questions={questions}
              setUserAnswer={setUserAnswer}
              answers={answers}
            />
          </ExamLayout>
        </>
      );
    default:
      return <ExamDebounce />;
  }
};

export default ExamSwitchScreen;
