import React from 'react';
import { Box } from '@components';

type Props = {
  children: React.ReactNode;
  className?: string | undefined;
};

const StaticLayout = ({ children, className = '' }: Props) => {
  return (
    <Box tag="section" className={`${className} min-h-[calc(100dvh-60px)] lg:min-h-[calc(100vh-190px)] py-8 lg:py-10`}>
      {children}
    </Box>
  );
};

export default StaticLayout;
