import React from 'react';
import { Box } from '../../components/atoms';
import AnswerOpticLetter from './answer-optic-letter';
import { useRouter } from 'next/router';


type Props = {
  answers?: string[];
  qIndex: number;
  setQIndex: (index: number) => void;
  locked: boolean;
  questions: any;
};
const AnswerList = ({ answers, setQIndex, qIndex, locked, questions }: Props) => {
  const router = useRouter()
  const device = router.query?.device
  return (
    <Box className={`col-span-12 lg:col-span-3 sticky top-0 bg-white z-[30] ${device==='mobile'?'pt-7':''}`}>
      <Box className="flex flex-grow-0 items-center lg:grid grid-cols-2 max-h-[calc(100vh-140px)] lg:overflow-x-hidden overflow-y-auto app-scrollbar gap-2 lg:gap-5 pr-4 lg:mt-0 mt-5">
        {answers?.map((i: string, index: number) => {
          return <AnswerOpticLetter key={index} questions={questions} locked={locked} answer={i} answers={answers} index={index} qIndex={qIndex} setQIndex={setQIndex} />;
        })}
      </Box>
    </Box>
  );
};

export default AnswerList;
