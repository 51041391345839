// Third Party Modules
import { useDispatch } from 'react-redux';
import { nextReduxCookieMiddleware, wrapMakeStore } from 'next-redux-cookie-wrapper';
import { createWrapper } from 'next-redux-wrapper';
import { AnyAction, ThunkAction, configureStore } from '@reduxjs/toolkit';
// Slices
import { AppSettingState, appSlice } from './slices';

// MakeStore
const makeStore = wrapMakeStore(() =>
  configureStore({
    reducer: { [appSlice.name]: appSlice.reducer },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(nextReduxCookieMiddleware({ subtrees: [`${appSlice.name}.user`, `${appSlice.name}.rememberMe`] })),
  }),
);
// Select Slices
export const selectApp = (state: AppState): AppSettingState => state[appSlice.name];
// Export Types
export type AppState = ReturnType<AppStore['getState']>;
export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunkAction<ReturnType = Promise<void>> = ThunkAction<ReturnType, AppState, unknown, AnyAction>;
// Export Dispatch && Wrapper
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
