import React from 'react';

type Props = {
  className?: string | undefined;
  children: React.ReactNode;
};

const ListItem = ({ className, children }: Props) => {
  return <li className={className}>{children}</li>;
};

export default ListItem;
