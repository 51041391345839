import React from 'react';
import { Modal as ResponsiveDrawer } from 'react-responsive-modal';
import classnames from 'classnames';
import DrawerBody from './drawer-body';
import DrawerCloseIcon from './drawer-close-icon';
import DrawerFooter from './drawer-footer';
import DrawerHeader from './drawer-header';

type Props = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  className?: string | undefined;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  animationDuration?: number | undefined;
  closeIcon?: boolean | undefined;
  title?: React.ReactNode | undefined;
  titleAlign?: 'left' | 'center' | 'right' | undefined;
  bodyClassName?: string | undefined;
  headerClassName?: string | undefined;
  borderHeader?: boolean | undefined;
  footerClassName?: string | undefined;
  borderFooter?: boolean | undefined;
  submitButton?: React.ReactNode | undefined;
  submitButtonVariant?:
    | 'none'
    | 'primary'
    | 'secondary-70'
    | 'secondary'
    | 'danger'
    | 'success'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-danger'
    | 'outline-success'
    | 'transparent'
    | undefined;
  fixedFooter?: boolean | undefined;
  isSubmitDisabled?: boolean | undefined;
  isSubmitLoading?: boolean | undefined;
  submitButtonClassName?: string | undefined;
};

const Drawer = ({
  open,
  onClose,
  borderHeader,
  borderFooter,
  isSubmitLoading,
  isSubmitDisabled,
  titleAlign,
  submitButtonClassName,
  fixedFooter,
  className,
  submitButtonVariant,
  animationDuration = 500,
  closeIcon = true,
  title,
  bodyClassName,
  headerClassName,
  footerClassName,
  submitButton,
  onSubmit,
  children,
}: Props) => {
  const classProps = classnames(
    'm-0 lg:px-9 lg:py-8 px-4 py-3 fixed top-0 bottom-0 z-[1000] right-0 bg-white w-screen lg:w-[31.25rem] overflow-hidden',
    className ? className : '',
  );

  return (
    <ResponsiveDrawer
      classNames={{ modal: classProps, modalContainer: 'relative', modalAnimationIn: 'customEnterModalAnimation', modalAnimationOut: 'customLeaveModalAnimation' }}
      animationDuration={animationDuration}
      closeIcon={<></>}
      blockScroll={false}
      open={open}
      onClose={() => onClose()}
    >
      <div className="relative dynamic-vh">
        <div className={`relative`}>
          <DrawerHeader title={title} align={titleAlign} className={headerClassName} /> {closeIcon && <DrawerCloseIcon onClick={() => onClose()} />}
        </div>
        {borderHeader && <div className="border-b my-3 border-[#29202033]"></div>}
        <DrawerBody className={bodyClassName} isFixedFooter={fixedFooter}>
          {children}
        </DrawerBody>
        {borderFooter && <div className="border-b my-3 border-[#29202033]"></div>}
        <DrawerFooter
          className={footerClassName}
          isSubmitLoading={isSubmitLoading}
          submitButtonClassName={submitButtonClassName}
          isSubmitDisabled={isSubmitDisabled}
          onSubmit={onSubmit}
          submitButton={submitButton}
          submitButtonVariant={submitButtonVariant}
        />
      </div>
    </ResponsiveDrawer>
  );
};

export default Drawer;
