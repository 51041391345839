import { useDeviceDetect } from '@hooks';
import React from 'react';
import { useIcon } from '../icons';

type Props = {
  onClick: () => void;
};
const DrawerCloseIcon = ({ onClick }: Props) => {
  const { CloseIcon } = useIcon();
  const { mobile } = useDeviceDetect();
  return (
    <span onClick={() => onClick()} className="flex cursor-pointer absolute right-0 top-1/2 translate-y-[-50%] p-0.5">
      <CloseIcon width={mobile() ? 22 : 26} height={mobile() ? 22 : 26} />
    </span>
  );
};

export default DrawerCloseIcon;
