import { Box, Span } from '@components';
import classnames from 'classnames';
import React from 'react';

type Props = {
  className?: string | undefined;
  noAnimate?: boolean | undefined;
};

const AnimateCircle = ({ className, noAnimate = false }: Props) => {
  const classProps = classnames(className ? className : '');
  return (
    <Box className={classProps}>
      <Box className="circle__box">
        <Box className="circle__wrapper circle__wrapper--right">
          <Span className={`circle__whole circle__right${noAnimate ? ' circle__whole circle__right--no-animate' : ''}`}></Span>
        </Box>
        <Box className="circle__wrapper circle__wrapper--left">
          <Span className={`circle__whole circle__left${noAnimate ? ' circle__whole circle__left--no-animate' : ''}`}></Span>
        </Box>
      </Box>
    </Box>
  );
};

export default AnimateCircle;
