import React, { useEffect, useState } from 'react';
import { isUndefined, size } from 'lodash';
import { scrollToElement } from '@utils';
import { Card, Span, Title } from '../../components/atoms';
import classnames from 'classnames';

type Props = {
  qIndex: number;
  setQIndex: (qIndex: number) => void;
  index: number;
  answers: string[];
  answer: string;
  locked: boolean;
  questions: any;
};

const AnswerOpticLetter = ({ qIndex, setQIndex, index, answers, answer, locked, questions }: Props) => {
  const [status, setStatus] = useState<undefined | boolean>(undefined);
  const rightOption = questions[index].rightOption;

  useEffect(() => {
    qIndex === index && scrollToElement(`opticLetter__${index}`);
  }, [qIndex]);

  useEffect(() => {
    if (locked) {
      if (rightOption === answer) setStatus(true); //* correct
      else if (isUndefined(answer)) setStatus(undefined); //* empty
      else if (rightOption !== answer && !isUndefined(answer)) setStatus(false); //* wrong
    }
  }, [locked]);

  const cardClassProps = classnames(
    `opticLetter__${index} lg:mb-0 mb-5 cursor-pointer flex flex-col items-center h-fit border-4`,
    index === qIndex ? ' border-blue-300 bg-blue-200' : size(answer) > 0 ? 'bg-blue-200 border-blue-100' : 'border border-gray-300',
    locked && status ? '!bg-green-50 border-green-200' : '',
    locked && status === false ? '!bg-red-50 border-red-200' : '',
    locked && isUndefined(status) ? '!border-gray-300 !bg-white' : '',
  );

  const letterClassProps = classnames(
    'text-xs lg:text-sm w-4 h-4 lg:h-5 lg:w-5 flex items-center justify-center font-bold text-gray-700 rounded-full',
    index === qIndex && size(answer) === 0 ? 'bg-blue-300' : size(answer) > 0 ? 'bg-white' : 'bg-gray-300',
    locked && isUndefined(status) ? '!bg-gray-300' : '',
  );

  return (
    <Card onClick={() => setQIndex(index)} isShadow={false} className={cardClassProps} padding="xs">
      <Title
        tag="h4"
        className={`text-[12px] whitespace-nowrap lg:text-sm text-center ${index === qIndex || size(answer) > 0 ? 'text-gray-700' : 'text-gray-400'} ${
          answers?.some((j: string) => j !== undefined) ? 'text-gray-700' : ''
        }`}
      >
        Soru - {index + 1}
      </Title>
      <Span className={letterClassProps}>{answer}</Span>
    </Card>
  );
};

export default AnswerOpticLetter;
