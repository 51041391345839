import classNames from 'classnames'
import React from 'react'

type Props = {
  children: React.ReactNode
  className?: string | undefined
}

const ModalBody = ({ children, className }: Props) => {
  const classProps = classNames(
    "my-8",
    className ? className : ''
  )
  return (
    <div className={classProps}>{children}</div>
  )
}

export default ModalBody