import React, { useState } from 'react';
import { Box, Button, Card, Paragraph, Title, useIcon } from '@components';
import { useDeviceDetect } from '@hooks';

type Props = {
  question: string;
  answer: string;
};

const SSQCard = ({ question, answer }: Props) => {
  const [open, setOpen] = useState(false);
  const { ChevronDownIcon } = useIcon();
  const { mobile } = useDeviceDetect();
  return (
    <Box>
      <Card className={`${open ? 'h-full' : 'h-20'} !py-7 pl-4 lg:!pl-7 !pr-0 flex flex-col gap-2 !shadow-md transition-all duration-200 border border-gray-100`}>
        <Box onClick={() => setOpen(!open)} className="relative">
          <Title className="text-[#0B3262] !font-medium !text-sm lg:!text-base cursor-pointer pr-16" tag="h2">
            {question}
          </Title>
          <Button
            className={`${
              open ? '-rotate-180 bg-[#0B3262]' : 'rotate-0'
            } transition-all duration-200 absolute rounded-full -top-1 lg:-top-2 right-6 shadow-inner lg:shadow-sm w-8 h-8 lg:h-10 lg:w-10 grid place-items-center border border-gray-200`}
          >
            <ChevronDownIcon color={open ? '#fff' : '#0B3262'} width={mobile() ? 18 : 24} height={mobile() ? 18 : 24} />
          </Button>
        </Box>
        {
          <Paragraph
            className={`${open ? 'opacity-1 h-full' : 'opacity-0 h-0'} transition-all ease-out duration-200 text-sm lg:text-base leading-6 lg:leading-7 mt-1 lg:mt-5 pr-6`}
          >
            {answer}
          </Paragraph>
        }
      </Card>
    </Box>
  );
};

export default SSQCard;
