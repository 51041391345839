import React from 'react';
import { Box, Container } from 'src/components/atoms';
import FooterAppLinks from './footer-app-links';
import FooterCategories from './footer-categories';
import FooterInfo from './footer-info';
import FooterStaticPageLinks from './footer-static-links';

const Footer = () => {
  return (
    <footer className="mt-10 lg:mt-20 bg-gray-100 bg-opacity-20 border border-gray-200">
      <Container>
        <Box className="grid grid-cols-1 lg:grid-cols-5  gap-5 lg:gap-10 py-4 lg:py-14">
          <Box className="col-span-1 lg:col-span-2 lg:border-none">
            <FooterInfo />
          </Box>
          <Box className="col-span-1 lg:block flex flex-col">
            <FooterCategories />
          </Box>
          <Box className="col-span-1">
            <FooterStaticPageLinks />
          </Box>
          <Box className="col-span-1">
            <FooterAppLinks />
          </Box>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
