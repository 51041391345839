import classnames from "classnames";
import React from "react";

type Props = {
  children: React.ReactNode;
  className?: string | undefined;
};

const Nav = ({ className, children }: Props) => {
  const classProps = classnames(className ? className : "");
  return <nav className={classProps}>{children}</nav>;
};

export default Nav;
