import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useDeviceDetect } from '@hooks';
import { Drawer } from 'src/components/drawer';
import { counts, topics } from 'src/mocks';
import { Box, Button, Paragraph, Select, Title, useIcon } from '@components';
import { examUrlParser } from '@utils';

const HomeHero = () => {
  const [open, setOpen] = useState(false);
  const { AppleIcon, GooglePlayIcon } = useIcon();
  const router = useRouter();
  const { mobile } = useDeviceDetect();
  const { handleSubmit, watch, setValue } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    //* initial value for own your exam
    if (open) {
      setValue('type', topics);
      setValue('count', counts[2]);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    router.push(examUrlParser(watch('type'), watch('count')));
    setOpen(false);
  };

  return (
    <>
      <Box className="grid grid-cols-1 lg:grid-cols-2 lg:gap-20 items-center h-full my-8 lg:my-14">
        <Box className="max-w-[650px] lg:order-1 order-2">
          {!mobile() && (
            <Box className="hidden lg:flex gap-3 xl:gap-10">
              <Title className="text-sm xl:text-lg font-bold mt-3 text-[#0B3262]" tag="h6">
                Mobil Uygulama
              </Title>
              <Box className="flex gap-2 xl:gap-5 mb-10">
                <Button className="border border-gray-100 text-sm hover:scale-110 bg-white transition-all duration-100 flex items-center justify-center gap-2 text-gray-800 shadow-md font-medium rounded-full px-5 py-3">
                  <AppleIcon width={24} height={24} />
                  App Store
                </Button>
                <Button className="border border-gray-100 text-sm hover:scale-110 bg-white transition-all duration-100 flex items-center justify-center gap-2 text-gray-800 shadow-md font-medium rounded-full px-5 py-3">
                  <GooglePlayIcon width={24} height={24} /> Google Play
                </Button>
              </Box>
            </Box>
          )}
          <Title className="text-[#0B3262] text-2xl lg:text-5xl">Kendi Sınavını Oluştur</Title>
          <Paragraph className="!text-[#0B3262] text-md lg:text-lg leading-6 lg:leading-7 font-light my-3 lg:my-5 max-w-[500px]">
            <strong>İlk yardım, trafik ve çevre, araç tekniği, trafik adabı</strong> ile ilgili tercih ettiğin konuları ve soru sayısını seç, kendi sınavını oluştur.
          </Paragraph>
          <Button onClick={() => setOpen(true)} className="font-semibold py-3 px-5 lg:py-5 lg:px-10" variant="primary">
            Kendi Sınavını Oluştur
          </Button>
        </Box>
        <Box className="col-span-1 w-full lg:mt-0 order-1 lg:order-2">
          <Image
            objectFit="cover"
            src={'/images/app/home/1.jpeg'}
            layout="intrinsic"
            alt="kendi_sinavini_olustur"
            className="overflow-hidden rounded-2xl"
            width="680px"
            height="390px"
          />
        </Box>
      </Box>
      <Drawer
        open={open}
        onClose={() => handleClose()}
        title="Kendi Sınavını Oluştur"
        submitButton="Oluştur"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonClassName="w-full"
        fixedFooter
      >
        <Paragraph className="mt-10 text-gray-600 text-sm">
          Sınavda karşınıza çıkabilecek pek çok soruyu sizler için hazırlıyoruz. Oluşturmak istediğiniz sınavdaki konuları ve soru sayısını seçebilirsiniz.
        </Paragraph>
        <Box className="flex flex-col gap-5 mt-10">
          <Title tag="h2" className="text-gray-800">
            Konular
          </Title>
          <Select isMulti selected={watch('type')} onChange={(event) => setValue('type', event)} closeMenuOnSelect={false} options={topics} />
        </Box>
        <Box className="flex flex-col gap-5 mt-10">
          <Title tag="h2" className="text-gray-800">
            Soru Sayısı
          </Title>
          <Select selected={watch('count')} onChange={(event) => setValue('count', event)} options={counts} />
        </Box>
        <Paragraph className="mt-10 text-gray-600 text-sm">
          <strong>İlk yardım, trafik ve çevre, araç tekniği, trafik adabı</strong> ile ilgili tercih ettiğin konuları ve soru sayısını seç, kendi sınavını oluştur.
        </Paragraph>
        <Paragraph className="mt-5 text-gray-600 text-sm">
          <strong> ehliyetsinavim.net</strong> tarafından hazırlanmış sınav sorularını çözerek sınav öncesi pratik yapabilirsiniz.
        </Paragraph>
      </Drawer>
    </>
  );
};

export default HomeHero;
