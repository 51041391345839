import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Modal } from '@components';
import { Box, Button, Logo, Title } from '../../components/atoms';
import { useDeviceDetect } from '@hooks';

type Props = {
  title: string;
  locked: boolean;
};

const ExamHeader = ({ title, locked }: Props) => {
  const { mobile } = useDeviceDetect();
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const device = router.query.device

  const handleSubmit = () => {
    router.push('/');
    setOpen(false);
  };

  const handleCancel = () => {
    if (!locked) {
      setOpen(true);
    } else router.push('/');
  };

  return (
  <Box>
{device==='mobile' ? <Box className="fixed top-0 left-0 right-0"></Box>:<>
      <Box className="grid grid-cols-12 lg:gap-10 py-3.5 pb-0">
        <Box className="col-span-12 lg:col-span-3">
          {mobile() ? (
            <Button onClick={() => handleCancel()}>
              <Logo noHref width={130} height={33} />
            </Button>
          ) : (
            <Button onClick={() => handleCancel()}>
              <Logo noHref width={220} height={55} />
            </Button>
          )}
        </Box>
        {!mobile() && (
          <Box className="col-span-12 lg:col-span-9 lg:flex lg:items-center px-5">
            <Title className="text-xl lg:text-2xl text-[#0B3262] !font-extrabold">{title}</Title>
          </Box>
        )}
      </Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        closeIcon={false}
        onSubmit={() => handleSubmit()}
        footerClassName="flex flex-col gap-5"
        submitButton="Sınavdan Çık"
        submitButtonVariant="none"
        cancelButton="İptal"
        cancelButtonVariant="secondary"
        className="max-w-md"
      >
        <Title tag="h2" className="text-lg text-center">
          Devam eden bir sınavınız var. Çıkmak istediğinizden emin misiniz?
        </Title>
        <Box className="flex flex-col gap-5 mt-5"></Box>
      </Modal>
    </>}
  </Box>
    
  );
};

export default ExamHeader;
