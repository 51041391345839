import React from 'react';
import classnames from 'classnames';

type Props = {
  children?: React.ReactNode;
  className?: string | undefined;
};

const Paragraph = ({ children, className }: Props) => {
  const classProps = classnames('text-gray-600', className ? className : '');

  return <p className={classProps}>{children}</p>;
};

export default Paragraph;
