import classNames from 'classnames';
import React from 'react';
import DrawerSubmitButton from './drawer-submit-button';

type Props = {
  submitButton?: React.ReactNode | undefined;
  submitButtonVariant?:
    | 'none'
    | 'primary'
    | 'secondary-70'
    | 'secondary'
    | 'danger'
    | 'warning'
    | 'success'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-danger'
    | 'outline-success'
    | 'transparent'
    | undefined;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string | undefined;
  isSubmitDisabled?: boolean | undefined;
  submitButtonClassName?: string | undefined;
  isSubmitLoading?: boolean | undefined;
  children?: React.ReactNode | undefined;
};

const DrawerFooter = ({ submitButton, children, isSubmitLoading, submitButtonVariant, submitButtonClassName, onSubmit, isSubmitDisabled, className }: Props) => {
  const classProps = classNames('absolute bottom-7 lg:bottom-14 left-0 right-0 z-20 bg-white', className ? className : '');
  return submitButton ? (
    <div className={classProps}>
      <DrawerSubmitButton isSubmitLoading={isSubmitLoading} className={submitButtonClassName} isSubmitDisabled={isSubmitDisabled} variant={submitButtonVariant} onClick={onSubmit}>
        {submitButton}
      </DrawerSubmitButton>
    </div>
  ) : (
    <>{children}</>
  );
};

export default DrawerFooter;
