import React from 'react';
import Lottie from 'react-lottie-player';
import { HeroAnimation } from '@styles/animations';
import { Box } from '../../components/atoms';
import { useDeviceDetect } from '@hooks';

const AuthHero = () => {
  const { mobile } = useDeviceDetect();
  return (
    <Box className="flex items-center justify-end relative flex-1 lg:h-screen flip">
      {mobile() === false ? (
        <Lottie loop animationData={HeroAnimation} play style={{ width: '100vw', height: '30vh' }} />
      ) : (
        <Lottie loop animationData={HeroAnimation} play style={{ width: '45vw', height: '70vh' }} />
      )}
    </Box>
  );
};

export default React.memo(AuthHero);
