export const ENDPOINTS = {
  AUTH: {
    REGISTER: '/auth/register',
    LOGIN: '/auth/login',
  },
  QUESTIONS: {
    ALL: '/question',
  },
  MONTHS: {
    BY_TYPE: '/question/{type}/months',
  },
  STATIC: {
    ALL: '/static',
    BY_TYPE: '/static/{type}',
  },
  FAQ: {
    ALL: '/faq',
  },
  BLOG: {
    ALL: '/blog',
  },
};
