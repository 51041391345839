import React from 'react';
import { Box } from '../../components/atoms';
import ExamHeader from './exam-header';
import ExamTimer from './exam-timer';

type Props = {
  children: React.ReactNode;
  title: string;
  answerSize: number;
  locked: boolean;
  setLocked: (locked: boolean) => void;
};

const ExamLayout = ({ children, title, answerSize, locked, setLocked }: Props) => {
  return (
    <Box className="relative">
      <ExamTimer answerSize={answerSize} locked={locked} setLocked={setLocked} />
      <ExamHeader title={title} locked={locked} />
      <Box className="grid grid-cols-12 lg:gap-10 lg:py-10 lg:pb-0 pb-24">{children}</Box>
    </Box>
  );
};

export default ExamLayout;
